## EchartsPane.vue

<template>
    <el-main>
        <el-breadcrumb separator="/">
            <el-breadcrumb-item :to="{ path: '/' }">首页</el-breadcrumb-item>
            <el-breadcrumb-item>智能数据中心</el-breadcrumb-item>
        </el-breadcrumb>
        <el-row :gutter="20" class="row-pane">
            <el-col :span="12" class="col-1">
                <el-row>
                    <el-col :span="2" style="width: 12px; height: 12px; background: #1890FF;
                        border-radius: 50%; margin: 20px 0 0 35px;"></el-col>
                    <el-col :span="22" style="width: 150px; height: 15px; font-size: 16px;
                        font-family: Alibaba PuHuiTi; font-weight: 400; color: #333333;
                        margin: 15px 0 0 10px;">餐厅近期运营数据</el-col>
                </el-row>
                <el-row>
                    <el-col style="width: 90%; height: 43px; font-size: 16px; font-family: Alibaba PuHuiTi;
                        font-weight: 400; color: #333333; margin: 15px 0 0 40px;">
                        近期店鋪平均取票到店率78%，平均等候时长3分钟。高于同期其他店铺。
                    </el-col>
                </el-row>
                <el-row>
                    <el-table :data="tableData" border>
                        <el-table-column header-align="center" align="center" prop="leftHeader" label="" width="100">
                        </el-table-column>
                        <el-table-column header-align="center" align="center" prop="number" 
						       label="6.20" width="80"></el-table-column>
                        <el-table-column header-align="center" align="center" prop="totalInvestment"
                                label="6.19" width="80"></el-table-column>
                        <el-table-column header-align="center" align="center" prop="totalComplete"
                                label="6.18" width="88"></el-table-column>
                        <el-table-column header-align="center" align="center" prop="curComplete"
                                label="6.17" width="100"></el-table-column>
                        <el-table-column header-align="center" align="center" prop="curCenterComplete"
                                label="6.16" width="100"></el-table-column>      
						<el-table-column header-align="center" align="center" prop="pingjun"
                                label="平均" width="100"></el-table-column>
                    </el-table>
                </el-row>
            </el-col>
            <el-col :span="12" class="col-2">
                <el-row>
                    <el-col :span="2" style="width: 12px; height: 12px; background: #1890FF;
                        border-radius: 50%; margin: 20px 0 0 35px;"></el-col>
                    <el-col :span="22" style="width: 150px; height: 15px; font-size: 16px;
                        font-family: Alibaba PuHuiTi; font-weight: 400; color: #333333;
                        margin: 15px 0 0 10px;">到店时间分布图</el-col>
                </el-row>
                <el-row>
                    <el-col style="width: 90%; height: 43px; font-size: 16px; font-family: Alibaba PuHuiTi;
                        font-weight: 400; color: #333333; margin: 15px 0 0 40px;">
                        本图显示店铺的到店时间分布。
                    </el-col>
                </el-row>
                <el-row>
                    <div id="echart1"></div>
                </el-row>
            </el-col>
        </el-row>
        <el-row :gutter="20" class="row-pane">
            <el-col :span="12" class="col-1">
                <el-row>
                    <el-col :span="2" style="width: 12px; height: 12px; background: #1890FF;
                        border-radius: 50%; margin: 20px 0 0 35px;"></el-col>
                    <el-col :span="22" style="width: 150px; height: 15px; font-size: 16px;
                        font-family: Alibaba PuHuiTi; font-weight: 400; color: #333333;
                        margin: 15px 0 0 10px;">新老客戶比例分析</el-col>
                </el-row>
                <el-row>
                    <el-col style="width: 90%; height: 43px; font-size: 16px; font-family: Alibaba PuHuiTi;
                        font-weight: 400; color: #333333; margin: 15px 0 0 40px;">
                        店鋪回頭客占比
                    </el-col>
                </el-row>
                <el-row>
                    <div id="echart23">
                        <div id="echart2"></div>
                        <div id="echart3"></div>
                    </div>
                </el-row>
            </el-col>
            <el-col :span="12" class="col-2">
                <el-row>
                    <el-col :span="2" style="width: 12px; height: 12px; background: #1890FF;
                        border-radius: 50%; margin: 20px 0 0 35px;"></el-col>
                    <el-col :span="22" style="width: 150px; height: 15px; font-size: 16px;
                        font-family: Alibaba PuHuiTi; font-weight: 400; color: #333333;
                        margin: 15px 0 0 10px;">订座类型分布比例</el-col>
                </el-row>
                <el-row>
                    <el-col style="width: 90%; height: 43px; font-size: 16px; font-family: Alibaba PuHuiTi;
                        font-weight: 400; color: #333333; margin: 15px 0 0 40px;">
                        
                    </el-col>
                </el-row>
                <el-row>
                    <div id="echart4"></div>
                </el-row>
            </el-col>
        </el-row>
    </el-main>
</template>

<script>
    export default {
        name: "EchartsPane",
        data(){
            return {
				tableData_daylist:['20','19','18','17','16','平均'],
                tableData: [{
                    leftHeader: 'A座',
                    number: ' 80%',
                    totalInvestment: '78%',
                    totalComplete: '68%',
                    curComplete: '88%',
                    curCenterComplete: '68%',
                    pingjun: '78%'
                }, {
                    leftHeader: 'B座',
                  number: ' 80%',
                  totalInvestment: '78%',
                  totalComplete: '68%',
                  curComplete: '88%',
                  curCenterComplete: '68%',
                  pingjun: '78%'
                }, {
                    leftHeader: 'C座',
                  number: ' 80%',
                  totalInvestment: '78%',
                  totalComplete: '68%',
                  curComplete: '88%',
                  curCenterComplete: '68%',
                  pingjun: '78%'
                }, {
                    leftHeader: 'D座',
                 number: ' 80%',
                 totalInvestment: '78%',
                 totalComplete: '68%',
                 curComplete: '88%',
                 curCenterComplete: '68%',
                 pingjun: '78%'
                }]
            }
        },

        mounted() {
            this.onInitEchart1();
            this.onInitEchart2();
          //  this.onInitEchart3();
            this.onInitEchart4();
        },

        methods: {
            onInitEchart1(){
                let echarts = require('echarts');
                let myChart = echarts.init(document.getElementById('echart1'), 'walden');

                let option = {
  title: {
    text: '时间线分布图',
    subtext: 'Fake Data'
  },
  tooltip: {
    trigger: 'axis',
    axisPointer: {
      type: 'cross'
    }
  },
  toolbox: {
    show: true,
    feature: {
      saveAsImage: {}
    }
  },
  xAxis: {
    type: 'category',
    boundaryGap: false,
    // prettier-ignore
    data: ['06:00', '07:00', '07:30', '08:00', '09:00', '10:00', '11:00', '12:00', '13:00', '14:00', '15:00', '16:00', '17:00', '18:00', '19:00', '20:00', '21:00', '22:00', '23:00', '24:00']
  },
  yAxis: {
    type: 'value',
    axisLabel: {
      formatter: '{value}'
    },
    axisPointer: {
      snap: true
    }
  },
  visualMap: {
    show: false,
    dimension: 0,
    pieces: [
      {
        lte: 6,
        color: 'green'
      },
      {
        gt: 6,
        lte: 8,
        color: 'red'
      },
      {
        gt: 8,
        lte: 14,
        color: 'green'
      },
      {
        gt: 14,
        lte: 17,
        color: 'red'
      },
      {
        gt: 17,
        color: 'green'
      }
    ]
  },
  series: [
    {
      name: 'Electricity',
      type: 'line',
      smooth: true,
      // prettier-ignore
      data: [0, 0, 20, 26, 38, 30, 55, 50, 40, 39, 38, 0, 0, 50, 60, 75, 80, 20, 0, 0],
      markArea: {
        itemStyle: {
          color: 'rgba(255, 173, 177, 0.4)'
        },
        data: [
          [
            {
              name: 'Morning Peak',
              xAxis: '07:30'
            },
            {
              xAxis: '10:00'
            }
          ],
          [
            {
              name: 'Evening Peak',
              xAxis: '17:30'
            },
            {
              xAxis: '21:15'
            }
          ]
        ]
      }
    }
  ]
};
				
                myChart.setOption(option);
            },
            onInitEchart2(){
                let echarts = require('echarts');
                let myChart = echarts.init(document.getElementById('echart2'), 'walden');
                let option = {
                    tooltip: {
                        trigger: 'item',
                        formatter: '{b}: {c} ({d}%)'
                    },
                    series: [
                        {
                            type: 'pie',
                            radius: ['50%', '70%'],
                            avoidLabelOverlap: false,
                            label: {
                                show: false,
                                position: 'center'
                            },
                            emphasis: {
                                label: {
                                    show: true,
                                    fontSize: '18',
                                    color: '#0078FF',
                                    formatter: '{b} ({d}%)'
                                }
                            },
                            labelLine: {
                                show: false
                            },
                            data: [
                                {value: 42.5, name: '老客戶', selected: true},
                                {value: 57.5, name: '新客戶'}
                            ]
                        }
                    ]
                };
                myChart.setOption(option);
                let index = 0;
                myChart.dispatchAction({
                    type: 'highlight',
                    seriesIndex: 0,
                    dataIndex: 0
                });
                myChart.on("mouseover", function(e) {
                    if (e.dataIndex != index) {
                        myChart.dispatchAction({
                            type: "downplay",
                            seriesIndex: 0,
                            dataIndex: index
                        });
                    }
                });
                myChart.on("mouseout", function(e) {
                    index = e.dataIndex;
                    myChart.dispatchAction({
                        type: "highlight",
                        seriesIndex: 0,
                        dataIndex: 0
                    });
                });
            },
            onInitEchart3(){
                let echarts = require('echarts');
                let myChart = echarts.init(document.getElementById('echart3'), 'walden');
                let option = {
                    tooltip: {
                        trigger: 'item',
                        formatter: '{b}: {c} ({d}%)'
                    },
                    series: [
                        {
                            type: 'pie',
                            radius: ['50%', '70%'],
                            avoidLabelOverlap: false,
                            label: {
                                show: false,
                                position: 'center'
                            },
                            emphasis: {
                                label: {
                                    show: true,
                                    fontSize: '18',
                                    color: '#0078FF',
                                    formatter: '{b} ({d}%)'
                                }
                            },
                            labelLine: {
                                show: false
                            },
                            data: [
                                {value: 42.5, name: '完建'},
                                {value: 57.5, name: '在建', selected: true}
                            ]
                        }
                    ]
                };
                myChart.setOption(option);
                let index = 1;
                myChart.dispatchAction({
                    type: 'highlight',
                    seriesIndex: 0,
                    dataIndex: 1
                });
                myChart.on("mouseover", function(e) {
                    if (e.dataIndex != index) {
                        myChart.dispatchAction({
                            type: "downplay",
                            seriesIndex: 0,
                            dataIndex: index
                        });
                    }
                });
                myChart.on("mouseout", function(e) {
                    index = e.dataIndex;
                    myChart.dispatchAction({
                        type: "highlight",
                        seriesIndex: 0,
                        dataIndex: 1
                    });
                });
            },
            onInitEchart4(){
                let echarts = require('echarts');
                let myChart = echarts.init(document.getElementById('echart4'), 'walden');
                let option = {
                    tooltip: {
                        trigger: 'item',
                        formatter: '{b}: {c} ({d}%)'
                    },
                    series: [
                        {
                            type: 'pie',
                            radius: ['50%', '70%'],
                            avoidLabelOverlap: false,
                            label: {
                                position: 'outer',
                                formatter: '{b}\n{c}人',
                                color: '#0078FF',
                                fontSize: 12,
                                lineHeight: 22
                            },
                            emphasis: {
                                label: {
                                    show: true
                                }
                            },
                            labelLine: {
                                show: true
                            },
                            data: [
                                {value: 26.4, name: 'A'},
                                {value: 31.5, name: 'B'},
                                {value: 30, name: 'C'},
                                {value: 62, name: 'D'},
                            ]
                        }
                    ]
                };
                myChart.setOption(option);
            }
        }
    }
</script>

<style scoped>
    .row-pane {
        height: 500px !important;
        margin: 20px 0 0 0 !important;
    }

    .col-1 {
        background-color: white;
        height: 500px !important;
        width: 45.6%;
        float: left;
    }

    .col-2 {
        background-color: white;
        height: 500px !important;
        width: 45.6%;
        float: right;
    }

    #echart1, #echart2, #echart3, #echart4 {
        height: 350px;
        width: 700px;
        margin: 0 auto;
    }

    #echart23 {
        display: flex;
    }
</style>
