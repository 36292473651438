<template>
	<div class="template-wrapper">
		<el-button @click="addtemplate">{{$t('addTemplate')}}</el-button>
		<!--        <el-tabs v-model="activeName" @tab-click="handleClick">
            <el-tab-pane :label="item.label" :name="item.id" v-for="item in tab" :key="item.id"></el-tab-pane>
        </el-tabs> -->
		<el-table :data="tableData.slice((currentPage - 1) * pageSize, currentPage * pageSize)" stripe
			style="width: 100%">
			<el-table-column prop="addtime" :label="$t('date')" width="180">
				<template slot-scope="scope">
					<div>{{scope.row.addtime | formatDate}} </div>
				</template>
			</el-table-column>
			<el-table-column prop="type" :label="$t('type')" width="180">
				<template slot-scope="scope">
					<div v-if="scope.row.type=='tianqi'">{{$t('tianqi')}} </div>
					<div v-if="scope.row.type=='huodong1'">拓客活动 </div>
					<div v-if="scope.row.type=='huodongyhq'">{{$t('huodongyhq')}} </div>

					<div v-if="scope.row.type=='image'">图片 </div>
					<div v-if="scope.row.type=='video'">視頻 </div>
					<div v-if="scope.row.type=='location'">位置 </div>
					<div v-if="scope.row.type=='txt'">{{$t('wenzi')}} </div>
					<div v-if="scope.row.type=='paidui'">{{$t('paidui')}} </div>
					<div v-if="scope.row.type=='template'">模板 </div>
					<div v-if="scope.row.type=='button'">按鈕 </div>
					<div v-if="scope.row.type=='list'">選擇列表 </div>
				</template>
			</el-table-column>

			<!--            <el-table-column
            prop="pageid"
            :label="$t('pageid')"
            width="">
            </el-table-column>  -->
			<el-table-column prop="sid" :label="$t('sid')" width="">
			</el-table-column>
			<el-table-column prop="upmsg" :label="$t('keyword')" width="">
			</el-table-column>
			<el-table-column prop="downmsg" :label="$t('downmsg')" width="">
			</el-table-column>



			<el-table-column prop="operation" label="操作" align="center" width="180">
				<template slot-scope="scope">
					<div class="operationVid">
						<span class="operationBef" @click="edititem(scope.row)">编辑</span>

					</div>
				</template>
			</el-table-column>


			<el-table-column prop="operation" label="操作" align="center" width="180">
				<template slot-scope="scope">
					<div class="operationVid">
						<span class="operationBef" @click="ewm(scope.row.upmsg,scope.row.pageid)">二维码</span>

					</div>
				</template>
			</el-table-column>

			<el-table-column prop="operation" label="操作" align="center" width="180">
				<template slot-scope="scope">
					<div class="operationVid">
						<span class="operationBef" @click="delitem(scope.row.id)">删除</span>



					</div>
				</template>
			</el-table-column>

		</el-table>
		<div class="pagination flex-center">
			<el-pagination background @current-change="handleCurrentChange" :current-page="currentPage"
				:page-size="pageSize" layout="total,prev,pager,next" :total="tableData.length"></el-pagination>
		</div>

		<!-- add -->
		<el-dialog :title="$t('addTemplate')" :visible.sync="dialogVisible"
			:width="$store.state.isMobile? '90%' :'556px'" :before-close="handleClose">
			<div class="add-template">
				<el-form ref="form" :model="form">

					<el-form-item :label="$t('dianpulist')">
						<el-select v-model="form.pageid" placeholder="">
							<el-option v-for="item in pagelist" :label="item.name" :value="item.pageid"></el-option>

						</el-select>
					</el-form-item>

					<el-form-item :label="$t('templateType')">
						<el-select v-model="form.type" placeholder="">
							<el-option :label="$t('txt')" value="txt"></el-option>
							<el-option :label="$t('tianqi')" value="tianqi"></el-option>
							<el-option :label="$t('paidui')" value="paidui"></el-option>
							<el-option :label="$t('huodong')" value="huodong1"></el-option>
							<el-option :label="$t('huodongyhq')" value="huodongyhq"></el-option>
							<el-option :label="$t('location')" value="location"></el-option>
							<el-option label="图片" value="image"></el-option>
							<el-option label="視頻" value="video"></el-option>
							<el-option label="模板" value="template"></el-option>
							<el-option label="按鈕" value="button"></el-option>
							<el-option label="選擇列表" value="list"></el-option>
						</el-select>
					</el-form-item>

					<el-form-item :label="$t('privately')">
						<el-select v-model="form.privately" placeholder="">
							<el-option :label="$t('true')" value="1"></el-option>
							<el-option :label="$t('false')" value="0"></el-option>


						</el-select>
					</el-form-item>

					<el-form-item v-if="form.type=='huodongyhq'" :label="$t('huodongid')">
						<el-select v-model="form.huodongID" placeholder="">
							<el-option v-for="item in huodonglist" :label="item.title" :value="item.id"></el-option>

						</el-select>
					</el-form-item>


					<el-form-item :label="$t('keyword')">
						<el-input v-model="form.upmsg"></el-input>
					</el-form-item>

					<el-form-item v-if="form.type=='button' || form.type=='list'" label="header">
						<el-input v-model="form.header"></el-input>
					</el-form-item>

					<el-form-item v-if="form.type=='template' || form.type=='image' || form.type=='button'" label="上传图片">
						<el-input v-model="form.image"></el-input>
						<el-upload :limit="1" ref="elUpload" action="" list-type="picture-card" :auto-upload="true"
							:http-request="uploadSectionFile" :on-remove="handleRemove">
							<i slot="default" class="el-icon-plus" />
							<div slot="file" slot-scope="{file}">
								<el-image class="el-upload-list__item-thumbnail" :src="file.url" alt="" />
								<span class="el-upload-list__item-actions">
									<span class="el-upload-list__item-preview" @click="handlePictureCardPreview(file)">
										<i class="el-icon-zoom-in" />
									</span>
									<span v-if="!disabled" class="el-upload-list__item-delete"
										@click="handleDownload(file)">
										<i class="el-icon-download" />
									</span>
									<span v-if="!disabled" class="el-upload-list__item-delete"
										@click="handleRemove(file)">
										<i class="el-icon-delete" />
									</span>
								</span>
							</div>
						</el-upload>

						<!-- <el-image-viewer :z-index="2002" v-if="showViewer" :on-close="closeViewer" :url-list="[dialogImageUrl]"/> -->



					</el-form-item>


					<!-- 				
				  <el-form-item v-if="form.type=='template'" label="图片地址[注意:如模板没有图片,则不要填写]">
                        <el-input v-model="form.head_img"></el-input>
			 	
                    </el-form-item>
					 -->

					<el-form-item v-if="form.type=='video'" label="視頻地址">
						<el-input v-model="form.image"></el-input>
					</el-form-item>

					<el-form-item v-if="form.type=='button' || form.type=='list'" label="buttonlist">
						<el-input v-model="form.buttonlist"></el-input>
					</el-form-item>

					<el-form-item v-if="form.type=='list'" label="list des">
						<el-input v-model="form.list_des"></el-input>
					</el-form-item>

					<el-form-item v-if="form.type=='list'" label="list button">
						<el-input v-model="form.list_button"></el-input>
					</el-form-item>

					<el-form-item v-if="form.type=='txt' || form.type=='image' || form.type=='button' || form.type=='list'" :label="$t('downmsg')">
						<el-input v-model="form.downmsg" type="textarea" :rows="2"></el-input>
					</el-form-item>

					<el-form-item v-if="form.type=='button' || form.type=='list'" label="footer">
						<el-input v-model="form.footer" type="textarea" :rows="2"></el-input>
					</el-form-item>

					

					<el-form-item v-if="form.type=='template'" label="模板ID">
						<el-input v-model="form.downmsg" type="textarea" :rows="1"></el-input>
					</el-form-item>


					<el-form-item v-if="form.type=='template'" label="變化文字1">
						<el-input v-model="form.msg1" type="textarea" :rows="2"></el-input>
					</el-form-item>


					<el-form-item v-if="form.type=='template'" label="變化文字2">
						<el-input v-model="form.msg2" type="textarea" :rows="2"></el-input>
					</el-form-item>

					<el-form-item v-if="form.type=='location'" label="longitude">
						<el-input v-model="form.longitude" type="textarea" :rows="1"></el-input>
					</el-form-item>

					<el-form-item v-if="form.type=='location'" label="latitude">
						<el-input v-model="form.latitude" type="textarea" :rows="1"></el-input>
					</el-form-item>

					<el-form-item v-if="form.type=='location'" label="location name">
						<el-input v-model="form.location_name" type="textarea" :rows="1"></el-input>
					</el-form-item>

					<el-form-item v-if="form.type=='location'" label="location address">
						<el-input v-model="form.location_address" type="textarea" :rows="1"></el-input>
					</el-form-item>



					<el-form-item :label="$t('sid')">
						<el-input v-model="form.sid" type="textarea" :rows="1"></el-input>
					</el-form-item>



					<!-- 		  
                    <el-form-item v-if="form.type == 2">
                        <img width="100%" :src="form.pic" alt="">
                        <el-upload
                            action="https://jsonplaceholder.typicode.com/posts/"
                            list-type="picture-card"
                            :on-preview="handlePictureCardPreview"
                            :on-remove="handleRemove">
                             <i class="el-icon-plus"></i>
                        </el-upload>
                    </el-form-item> -->
				</el-form>
			</div>
			<span slot="footer" class="dialog-footer">
				<el-button @click="dialogVisible = false">{{$t('Cancel')}}</el-button>
				<el-button type="primary" @click="sure">{{$t('Sure')}}</el-button>
				<!-- <el-button v-if="form.type != 1"  type="primary" @click="submitImportForm">{{$t('Sure')}}{{form.type}}</el-button> -->
			</span>
		</el-dialog>

		<el-dialog :title="$t('ewm')" :visible.sync="dialogVisible_ewm" width="556px" :before-close="handleClose">
			<div class="add-template">


				<div>Whatsapp:<a :href="ewmdata.wurl">Link</a>
					<!-- 	<button class="tag-read" :data-clipboard-text="ewmdata.wurl" @click="copy">复制</button> -->
					<div><img :src="ewmdata.whatsapp" /></div>
				</div>


			</div>
		</el-dialog>


	</div>
</template>
<script>
	import {
		formatDate
	} from '../../assets/date.js'
	import axios from 'axios'
	import {
		tongyong,
		funk,
		imageUpload
	} from '@/api'
	import {
		addAutoTemplate
	} from '@/api'
	export default {
		data() {
			return {
				dialogImageUrl: '',
				fileList: [],
				disabled: false,
				showViewer: false, // 显示查看器
				ewmdata: [],
				fileList: [],
				pagelist: [],
				huodonglist: [],
				currentPage: 1, // 当前页码
				pageSize: 20, // 每页显示的行数
				activeName: '0',
				dialogVisible: false,
				dialogVisible_ewm: false,
				tab: [{
						label: this.$t('underApproval'),
						id: '0'
					},
					{
						label: this.$t('passed'),
						id: '1'
					}
				],
				tableData: [],
				tableData1: [

					{
						timer: '2018-01-01',
						name: '测试模板001',
						status: '审核中'
					},
				],
				status: 0,
				form: {
					type: 'txt',
					upmsg: '',
					downmsg: '',
					sid: 0,
					qyid: '',
					pageid: '',

				},
			}
		},
		filters: {
			formatDate: function(time) {
				time = time * 1000 //注意：time应为格式为13位unix时间戳，如果是10位的unix时间戳，需要乘以1000
				let date = new Date(time)
				return formatDate(date, 'yyyy-MM-dd hh:mm:ss')
			}
		},

		created() {
			this.gettemplist()
			this.getpagelist()
			this.gethuodonglist()
		},
		methods: {
			addtemplate() {
				this.dialogVisible = true
				this.form = {}
			},
			edititem(data) {
				this.form = data
				this.dialogVisible = true
			},
			beforeHandleCommand(e) {
				console.log(e);
			},
			// 页面切换方法
			handleCurrentChange(val) {
				this.currentPage = val;
			},
			handleClick(tab, event) {
				console.log(tab.index);
				this.status = tab.index
				this.currentPage = 1
				this.gettemplist()
			},
			handleClose() {
				this.dialogVisible = false
				this.dialogVisible_ewm = false
			},
			handlePictureCardPreview() {

			},
			handleRemove() {

			},
			// 添加模版点击确定
			sure() {
				var user = JSON.parse(window.localStorage.getItem('userinfo'))
				console.log(user);

				this.form.qyid = user.id

				addAutoTemplate(this.form).then(res => {
					if (res.code == 1) {
						this.$message({
							type: 'success',
							message: res.msg
						})
						this.dialogVisible = false
						this.gettemplist()
					} else {
						this.$message({
							type: 'error',
							message: res.msg
						})
					}
				})
			},
			//删除某个
			delitem(id) {
				this.$confirm('您确定删除吗？').then(_ => {

					console.log('del..');
					let fdata = {
						sjk: 'messenger_autolist',
						action: 'del',
						id: id
					}
					tongyong(fdata).then(res => {
						if (res.code == 1) {
							console.log(res);
							this.gettemplist()
						} else {
							this.$message({
								type: 'error',
								message: res.msg
							})
						}
					})
				}).catch(_ => {



				})


			},
			// 我的模板列表
			gettemplist() {
				console.log('go taocan');
				var user = JSON.parse(window.localStorage.getItem('userinfo'))

				let fdata = {
					sjk: 'messenger_autolist',
					action: 'list',
					// status:this.status,
					// moresearch:'status,',
					qyid: user.id
				}
				tongyong(fdata).then(res => {
					if (res.code == 1) {
						console.log(res);
						this.tableData = res.data
					} else {
						this.$message({
							type: 'error',
							message: res.msg
						})
					}
				})
			},


			//pagelist 
			getpagelist() {
				console.log('go taocan');
				var user = JSON.parse(window.localStorage.getItem('userinfo'))

				let fdata = {
					sjk: 'messenger_pagelist',
					action: 'list',

					qyid: user.id
				}
				tongyong(fdata).then(res => {
					if (res.code == 1) {
						console.log(res);
						this.pagelist = res.data
					} else {
						this.$message({
							type: 'error',
							message: res.msg
						})
					}
				})
			},

			//pagelist 
			gethuodonglist() {
				console.log('go taocan');
				var user = JSON.parse(window.localStorage.getItem('userinfo'))

				let fdata = {
					sjk: 'messenger_huodong_yhq',
					action: 'list',

					qyid: user.id
				}
				tongyong(fdata).then(res => {
					if (res.code == 1) {
						console.log(res);
						this.huodonglist = res.data
					} else {
						this.$message({
							type: 'error',
							message: res.msg
						})
					}
				})
			},


			ewm(str, pageid) {
				var user = JSON.parse(window.localStorage.getItem('userinfo'))
				console.log(user);
				this.dialogVisible_ewm = true
				this.qyid = user.id
				let fdata = {
					do: 'ewm',
					qyid: this.qyid,
					pageid: pageid,
					ref: str,
					type: 'link',


				}
				console.log('go ewm', fdata);


				funk(fdata).then(res => {
					if (res.code == 1) {
						console.log(res);
						this.ewmdata = res
						console.log(this.ewmdata)
						// const html ='<div style="text-algin:center;">H5:<a target="_blank"  href="'+res.url+'">link</a> <br><img src='+res.path+' /> <br>Whatsapp:<a target="_blank"  href="'+res.wurl+'">link</a> <button class="tag-read" :data-clipboard-text="aaa" @click="copy">复制</button></el-button><br> <img src='+res.whatsapp+' /> <br></div>' 
						// this.$alert(html, '預約排隊二維碼', {
						//           dangerouslyUseHTMLString: true
						//         });


					} else {
						this.$message({
							type: 'error',
							message: res.msg
						})
					}
				})
			},
			//上传--

			// 删除
			handleRemove(file) {
				let uploadFiles = this.$refs.elUpload.uploadFiles;
				uploadFiles.forEach((value, index) => {
					if (value.uid === file.uid) {
						uploadFiles.splice(index, 1)
						this.fileList.splice(index, 1)
					}
				})
			},
			// 大图预览
			handlePictureCardPreview(file) {
				this.dialogImageUrl = file.url;
				this.showViewer = true;
			},
			// 下载图片
			handleDownload(file) {
				let a = document.createElement('a');
				a.download = file.name;
				a.href = (file.url);
				a.click();
			},
			// 重写上传
			uploadSectionFile(params) {
				const file = params.file,
					fileType = file.type,
					isImage = fileType.indexOf('image') !== -1,
					isLt2M = file.size / 1024 / 1024 < 2;
				// 这里常规检验，看项目需求而定
				if (!isImage) {
					this.$message.error('只能上传图片格式png、jpg、gif!');
					return;
				}
				if (!isLt2M) {
					this.$message.error('只能上传图片大小小于2M');
					return;
				}
				// 根据后台需求数据格式
				const form1 = new FormData();
				// 文件对象
				form1.append('file', file);
				// 本例子主要要在请求时添加特定属性，所以要用自己方法覆盖默认的action
				// form.append("clientType", 'xxx');
				// 项目封装的请求方法，下面做简单介绍


				imageUpload(form1).then(res => {
					//自行处理各种情况
					console.log('res', res)
					this.form.image = res
				}).catch(() => {
					// xxx
				});
			},
			// 关闭
			closeViewer() {
				this.showViewer = false;
			},




		}
	}
</script>
<style lang="scss" scoped>
	.pagination {
		width: 100%;
		margin: 30px auto;
	}

	::v-deep .el-select {
		width: 100%;

	}
</style>
