<template>
    <div class="template-wrapper">
        <el-button @click="dialogVisible_duo=true">{{$t('sendall')}}</el-button>
   <!--     <el-tabs v-model="activeName" @tab-click="handleClick">
            <el-tab-pane :label="item.label" :name="item.id" v-for="item in tab" :key="item.id"></el-tab-pane>
        </el-tabs> -->
        <el-table
            :data="tableData.slice((currentPage - 1) * pageSize, currentPage * pageSize)"
            stripe
            style="width: 100%">
			<el-table-column
			prop="addtime"
			:label="$t('date')"
			width="180">
			<template slot-scope="scope">
			         <div>{{scope.row.addtime | formatDate}} </div>
			       </template>
			</el-table-column>
            <el-table-column v-for="item in sjkData"
				:prop="item.key"
				:label="item.title"
				>
				  <template   slot-scope="scope">
				  <div v-if="item.type=='choose'">{{scope.row[item.key]=='0'? item.optionst[0]:item.optionst[1]}}</div>
				  <div v-if="item.type!='choose'">{{scope.row[item.key]}} </div>
				  
				</template>  
            </el-table-column>
           
 
			  <el-table-column
			      prop="operation"
			      label="操作"
			      align="center"
			      width="180">
			     <template slot-scope="scope">
			     <div class="operationVid">
			       <span class="operationBef" @click="huifu(scope.row)" >回复</span>
			     
			    
 
			    </div>
			     </template>
			    </el-table-column>
		 		  <el-table-column
			      prop="operation"
			      label="操作"
			      align="center"
			      width="180">
			     <template slot-scope="scope">
			     <div class="operationVid">
			       <span class="operationBef" @click="edititem(scope.row)" >编辑</span>
			     
			    
 
			    </div>
			     </template>
			    </el-table-column>
		 
        </el-table>
        <div class="pagination flex-center">
            <el-pagination background  @current-change="handleCurrentChange"
    :current-page="currentPage"
    :page-size="pageSize"   layout="total,prev,pager,next" :total="tableData.length"></el-pagination>
        </div>

       <!-- 回复单个客户 -->
        <el-dialog :title="$t('reply')" :visible.sync="dialogVisible" width="556px" :before-close="handleClose">
            <div class="add-template">
                <el-form ref="form" :model="form">

         <!--           <el-form-item :label="$t('templateType')">
                        <el-select v-model="form.type" placeholder="">
                            <el-option :label="$t('textTemplate')" value="1"></el-option>
                            <el-option :label="$t('picturesAndText')" value="2"></el-option>
                            <el-option :label="$t('ComprehensiveTemplate')" value="3"></el-option>
                        </el-select>
                    </el-form-item>
 -->
                    <span>replyto:{{hfdata.name}}</span>
                    <!-- <span>whatsapp:{{hfdata.whatsapp}}</span> -->
                    <el-form-item :label="$t('replytxt')">
                        <el-input v-model="form.msg" type="textarea" :rows="2"></el-input>
                    </el-form-item>
					<el-form-item :label="$t('templateid')">
                        <el-input v-model="form.templateid" type="textarea" :rows="2"></el-input>
                    </el-form-item>

        
                </el-form>
            </div>
            <span slot="footer" class="dialog-footer">
                <el-button @click="dialogVisible = false"> {{$t('Cancel')}}</el-button>
                <el-button type="primary" @click="replyto">{{$t('Sure')}}</el-button>
            </span>
        </el-dialog>
		 <!-- 回复单个客户结束 -->
		
		
		<!-- 回复多个客户 -->
		       <el-dialog :title="$t('replyall')" :visible.sync="dialogVisible_duo" width="556px" :before-close="handleClose">
		           <div class="add-template">
		               <el-form ref="form" :model="form">
		
		        <!--           <el-form-item :label="$t('templateType')">
		                       <el-select v-model="form.type" placeholder="">
		                           <el-option :label="$t('textTemplate')" value="1"></el-option>
		                           <el-option :label="$t('picturesAndText')" value="2"></el-option>
		                           <el-option :label="$t('ComprehensiveTemplate')" value="3"></el-option>
		                       </el-select>
		                   </el-form-item>
		-->
		                   <span>replyto:all</span>
		                   <!-- <span>whatsapp:{{hfdata.whatsapp}}</span> -->
		                   <el-form-item :label="$t('replytxt')">
		                       <el-input v-model="form.msg" type="textarea" :rows="2"></el-input>
		                   </el-form-item>
							<el-form-item :label="$t('templateid')">
		                       <el-input v-model="form.templateid" type="textarea" :rows="2"></el-input>
		                   </el-form-item>
		
		       
		               </el-form>
		           </div>
		           <span slot="footer" class="dialog-footer">
		               <el-button @click="dialogVisible_duo = false"> {{$t('Cancel')}}</el-button>
		               <el-button type="primary" @click="replytoall">{{$t('Sure')}}</el-button>
		           </span>
		       </el-dialog>
				 <!-- 回复多个客户结束 -->
				 
				 
				  
		 
		<!-- 修改客户资料 -->
		
		  <el-dialog :title="$t('edit')" :visible.sync="dialogVisible_key" width="556px" :before-close="handleClose">
		           <div class="add-template">
		               <el-form ref="form" :model="formkey">
		
		           
				 			<el-form-item :label="$t('memo')">
							 <span> 备注</span>
		                       <el-input v-model="formkey.memo"></el-input>
								 
		                   </el-form-item> 
						   
							<el-form-item :label="$t('level')">
							 <span> </span>
		                       <el-input v-model="formkey.level"></el-input>
								 
		                   </el-form-item>  
 
		             
		               </el-form>
		           </div>
		           <span slot="footer" class="dialog-footer">
		               <el-button @click="dialogVisible_key = false">{{$t('Cancel')}}</el-button>
		               <el-button type="primary" @click="surekey">{{$t('Sure')}}</el-button>
		           </span>
		       </el-dialog>
				
		<!-- 修改客户资料结束 -->		

    </div>
</template>
<script>
		import { formatDate } from '../../assets/date.js'
import {tongyong,sjk,funk,api_tongyong_edit } from '@/api'
import {addTemplate} from '@/api'
export default {
    data() {
        return {
			hfdata:[],
			sjk:'messenger_kehulist',
			currentPage: 1,  // 当前页码
			pageSize: 15,  // 每页显示的行数
            activeName: '0',
            dialogVisible: false,
            dialogVisible_key: false,
            dialogVisible_duo: false,
            tab: [
                {label: this.$t('underApproval'), id: '0'},
                {label: this.$t('passed'), id: '1'}
            ],
			tableData:[],
			sjkData:[],
            tableData1: [
              
            ],
			status:0,
            form: {
                templateid: '',
                msg: '',
              
            },
			sform: {
							  parmas:{}
			},
			formkey:{
				keyword:'kkk'
			}
        }
    },
	filters: {
	  formatDate: function (time) {
	          time = time * 1000     //注意：time应为格式为13位unix时间戳，如果是10位的unix时间戳，需要乘以1000
	                   let date = new Date(time)
	                   return formatDate(date, 'yyyy-MM-dd hh:mm:ss')
	    } 
	  },
	created(option) {
		
			
					console.log('sjk',this.sjk);
					if(this.sjk){
					   this.getsjk()
					   this.gettemplist()
				   }
			  },
    methods: {
		edititem(data){
			this.dialogVisible_key=true
			this.action='edit'
			this.formkey = data 
		},
		huifu(data){
			this.dialogVisible=true 
			this.hfdata = data 
		},
		beforeHandleCommand(e){
			console.log(e);
		},
		 // 页面切换方法
		  handleCurrentChange(val) {
		    this.currentPage = val;
		  },
        handleClick(tab, event) {
            console.log(tab.index);
			this.status=tab.index
			this.currentPage=1
			 this.gettemplist()
        },
        handleClose() {
            this.dialogVisible = false 
            this.dialogVisible_key = false 
            this.dialogVisible_duo = false 
        },
        handlePictureCardPreview() {

        },
        handleRemove() {

        },
        // 添加模版点击确定
        sure() {
			 var user = JSON.parse(window.localStorage.getItem('userinfo'))
			 console.log(user);
		 
			 this.form.qyid= user.id
			 this.form.status= 0
            addTemplate(this.form).then(res => {
                if(res.code==1){
                    this.$message({
                        type: 'success',
                        message: res.msg
                    })
                    this.dialogVisible = false
					  this.gettemplist()
                }else{
                    this.$message({
                        type: 'error',
                        message: res.msg
                    })
                }    
            })
        },
		//删除某个
		delitem(id){
			this.$confirm('您确定删除吗？').then(_ => {
			
			    console.log('del..');
				let  fdata= {
						sjk: this.sjk,
						action:'del',
						id:id
					}
				tongyong(fdata).then(res => {
					if(res.code==1){
					  console.log(res);
					  this.gettemplist()
					}else{
						this.$message({
							type: 'error',
							message: res.msg
						})
					}    
				})
			}).catch(_ => {
			
			　 
			
			})
			
			
		},
		// 我的模板列表
		gettemplist(){
			
			var user = JSON.parse(window.localStorage.getItem('userinfo'))
 
			let  fdata= {
					sjk: this.sjk,
					action:'list',
					// status:this.status,
					// moresearch:'status,',
					qyid:user.id
				}
				console.log('go taocan',fdata);
			tongyong(fdata).then(res => {
				if(res.code==1){
				  console.log(res);
				  this.tableData=res.data
				}else{
					this.$message({
						type: 'error',
						message: res.msg
					})
				}    
			})
		},
		// 读取数据库结构
		getsjk(){
			console.log('go sjk');
	 
			let  fdata= {
					sjk: this.sjk,
				
				}
			sjk(fdata).then(res => {
				if(res.code==1){
				  console.log('sjk',res);
				  this.sjkData=res.data
				}else{
					this.$message({
						type: 'error',
						message: res.msg
					})
				}    
			})
		},
		replyto(){
			var user = JSON.parse(window.localStorage.getItem('userinfo'))
			console.log(user);
					 
			this.qyid= user.id
		   	let  fdata= {
		   			do: 'sendwhatsapp',
		   		    qyid:this.qyid ,
		   			pageid:this.pageid,
					msg:this.form.msg ,
					Phone:this.hfdata.whatsapp,
					templateid:this.form.templateid
		
		   		}
		this.$confirm('確定要發送嗎？').then(_ => {	 
		   	funk(fdata).then(res => {
		   		if(res.code>0){
		   		  console.log(res);
		   	      this.gettemplist()
				  this.$message({
				      type: 'success',
				      message: 'ok'
				  })
				  this.dialogVisible = false;
		   		}else{
		   			this.$message({
		   				type: 'error',
		   				message: 'error'
		   			})
		   		}    
		   	})
			}).catch(_ => {
			
			　 
			
			})
		},
		
		
		replytoall(){
			var user = JSON.parse(window.localStorage.getItem('userinfo'))
			console.log(user);
					 
			this.qyid= user.id
		   	let  fdata= {
		   			do: 'api_sendrw_insert',
		   		    qyid:this.qyid ,
		   			pageid:this.pageid,
					msg1:this.form.msg ,
					language:'zh_HK' ,
					type:'businessApi' ,
					daifaid:'9999' ,
					title:'PC群发' ,
				 
					templateid:this.form.templateid
		
		   		}
		this.$confirm('您確定要發送吗？').then(_ => {	 
		   	funk(fdata).then(res => {
		   		if(res.code>0){
		   		  console.log(res);
		   	      this.gettemplist()
				  this.$message({
				      type: 'success',
				      message: 'ok'
				  })
				  this.dialogVisible_duo = false;
		   		}else{
		   			this.$message({
		   				type: 'error',
		   				message: 'error'
		   			})
		   		}    
		   	})
			}).catch(_ => {
			
			　 
			
			})
		},
		// 修改key
		surekey() {
			 var user = JSON.parse(window.localStorage.getItem('userinfo'))
			 console.log(user);
		 
			 
			 this.formkey.sjk   = 'messenger_kehulist'
			 this.formkey.updatekey   = 'level,memo'
			 this.formkey.setkey   ='id' 
			 
			 
		 
			  let data = JSON.stringify(this.formkey);
			 
			  this.sform.parmas= data
			 
		    api_tongyong_edit(this.sform).then(res => {
				console.log(res);
		        if(res.code==1){
		            this.$message({
		                type: 'success',
		                message: res.msg
		            })
		            this.dialogVisible_key = false
				 
		        }else{
		            this.$message({
		                type: 'error',
		                message: res.msg
		            })
		        }    
		    })
		},
		
    },
	
	

}
</script>
<style lang="scss" scoped>
    .pagination{
        width: 100%;
        margin: 30px auto;
    }
    ::v-deep .el-select{
            width: 100%;
            
    }
  
</style>