<template>
  <div class="calendar">
    <el-calendar v-model="value">
      <template slot="dateCell" slot-scope="{ data }">
        <div class="poR">
          <span>{{ data.day.split("-").slice(2).join("-") }}</span>
          <!-- 遮罩层 -->
          <div v-for="(item, index) in calendarArr" :key="index">
            <div v-if="(item.isDate.split('-').slice(0)[0]).indexOf(data.day.split('-').slice(0)[0]) != -1">
              <div v-if="(item.isDate.split('-').slice(1)[0]).indexOf(data.day.split('-').slice(1)[0])!=-1">
                <div v-if="(item.isDate.split('-').slice(2).join('-')).indexOf(data.day.split('-').slice(2).join('-'))!=-1">
                  <div 
                    :class="data.day === item.isDate && item.status === 1 ? 'isStautus1' : data.day === item.isDate && item.status === 2 ? 'isStautus2' : data.day === item.isDate && item.status === 3 ? 'isStautus3' : 'unActive'"
                     class="calendar-day"
                   >
                  </div>
                </div>
                <div v-else></div>
              </div>
              <div v-else></div>
            </div>
            <div v-else></div>
          </div>
        </div>
      </template>
    </el-calendar>
  </div>
 
</template>

<script>
  export default {
    data() {
      return {
        value: new Date(), // 当前月份
        queryMonth:12, // 查询月份 yyyy-MM
        dateObj: {
          isDate: "",
          status: "" // 正常 加班 异常
        },
        calendarArr: [{isDate:8,status:'hi'}],
        calendarData: {
          status1: "",
          status2: "",
          status3: "",
          status4: "",
          status5: ""
        }
      }
    },
    // 切换月份
    watch: {
      value: function() {
        this.dateObj.status = ""
        this.dateObj.isDate = ""
      //  this.calendarArr = []
        var year = this.value.getFullYear();
        var month = this.value.getMonth() + 1;
        if (month >= 1 && month <= 9) {
          month = "0" + month;
        }
        this.queryMonth = year + "-" + month;
        // getAttenceRecords({ queryMonth: this.queryMonth }).then(res => {
        //   this.calendarData = res.data;
        //   res.data.items.forEach(el => {
        //     this.dateObj = {}
        //     this.dateObj.isDate = el.recordTime
        //     this.dateObj.status = el.status
        //     this.calendarArr.push(this.dateObj)
        //   })
        // })
      }
    },
  }
</script>

<style lang="scss" scoped>
  // 日历样式
  .calendar {
     .el-calendar__header {
      background-color: #fbfbfb;
      border: 1px solid #ebeef5;
      border-bottom: none;
    }
     .el-calendar__body {
      padding: 0;
    }
    .el-calendar-table thead th {
      border-top: 1px solid #ebeef5;
      border-right: 1px solid #ebeef5;
    }
    .el-calendar-table thead th:first-child {
      border-left: 1px solid #ebeef5;
    }
   .el-calendar-table .el-calendar-day {
      height: 40px;
      line-height: 40px;
      padding: 0;
      text-align: center;
    }
  }
  .calendar-day {
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
    width: 30px;
    height: 30px;
    border-radius: 50%;
    opacity: 0.6;
  }
  .isStautus1 {
    background-color: #66ff7c;
  }
  .isStautus2 {
    background-color: #00a0e9;
  }
  .isStautus3 {
    background-color: #ff0000;
  }
  // 出勤统计
  .stats {
    > div {
      text-align: center;
      padding: 10px 0;
      border-left: 1px solid #ebeef5;
      border-right: 1px solid #ebeef5;
      background-color: #fbfbfb;
    }
    ul {
      border-top: 1px solid #ebeef5;
      border-left: 1px solid #ebeef5;
      li {
        flex: 1;
        padding: 20px 0;
        border-right: 1px solid #ebeef5;
        border-bottom: 1px solid #ebeef5;
        p {
          padding: 10px;
          text-align: center;
        }
      }
    }
  }
  .status {
    padding-top: 20px;
    span {
      margin-right: 30px;
    }
    .dot {
      display: inline-block;
      width: 8px;
      height: 8px;
      border-radius: 50%;
    }
  }

// 字体颜色
.Cgreen {
  color: #66ff7c;
}
.Cblue {
  color: #00a0e9;
}
.Cred {
  color: #ff0000;
}
// 背景颜色
.bgcGreen {
  background-color: #66ff7c;
}
.bgcBlue {
  background-color: #00a0e9;
}
.bgcRed {
  background-color: #ff0000;
}
</style>