<template>
	<div>
		<div class="css-wgqwlq">
			<div class="tishi">{{ tishi }}</div>
			<div> <img style="max-width: 200px;" v-if="ewm" :src="ewm" /></div>
			<div></div>
			<div class="tishi" v-if="loginfo.me">{{ loginfo.pushname }}{{ loginfo.me.user }}</div>
			<div class="tishi" v-if="tishi=='登录成功'"> <el-button @click="gettxl">获取通讯录</el-button></div>
		</div>


	</div>

</template>

<script>
import axios from 'axios'
import { funk } from '@/api'
export default {
	data() {
		return {
			tishi: '请打开whatsapp扫码接入',
			qyid: '', pageid: '',
			action: '',
			time: '',
			whatsapp: '',
			ewm: '',	loginfo: [],
			sjk: 'messenger_paiduilist',
		

		}
	},
	created(option) {
		var user = JSON.parse(window.localStorage.getItem('userinfo'))
		console.log(user);

		this.qyid = user.id

		if (this.$route.query.whatsapp) {
			this.whatsapp = this.$route.query.whatsapp
		}
		if (this.$route.query.pageid) {
			this.pageid = this.$route.query.pageid
		}

		console.log('whatsapp', this.whatsapp);
		console.log('pageid', this.pageid);

	},
	mounted() {
		if (this.whatsapp) {
			this.timer = setInterval(this.whatslogin, 2000);
		}
	},
	methods: {


		edititem(data) {
			this.dialogVisible = true
			this.action = 'edit'
			this.form = data
		},
		//login w
		whatslogin() {
			let fdata = {
				do: 'api_whatsapp_login',
				action: 'begin',
				whatsapp: this.whatsapp,
			}
			funk(fdata).then(res => {
				console.log(res.code);
				if (res.ewm) {
					this.ewm = res.ewm
				}
				if (res.code === 0) {
					console.log('clear..');
					this.getContacts()
					// this.whatsapp=''
					this.tishi = '登录成功'
					this.$message({
					    type: 'success',
					    message: '登录成功'
					})
					clearInterval(this.timer);
				}
			})

		},
		whatsinfo() {
			let fdata = {
				do: 'api_whatsapp_login',
				action: 'info',
				whatsapp: this.whatsapp,
				qyid: this.qyid,
				pageid: this.pageid

			}
			funk(fdata).then(res => {
				//console.log(res);
				this.loginfo = res

			})

		},

		getContacts() {
			let fdata = {
				do: 'api_whatsapp_login',
				action: 'getContacts',
				whatsapp: this.whatsapp,
				qyid: this.qyid,
				pageid: this.pageid

			}
			funk(fdata).then(res => {
				 console.log(res);
				//this.loginfo = res

			})

		},
		gettxl() {
			let fdata = {
				do: 'api_whatsapp_login',
				action: 'gettxl',
				whatsapp: this.whatsapp,
				qyid: this.qyid,
				pageid: this.pageid

			}
			funk(fdata).then(res => {
				 console.log(res);
				//this.loginfo = res
				 this.$message({
				     type: 'success',
				     message: '导入'+res.count +'个'
				 })
				 
				  this.$router.push('/pendingList');

			})

		},

		// 读取数据库结构


	}
}

</script>

<style>
.css-wgqwlq {
	background-color: rgb(255, 255, 255);
	color: rgb(35, 41, 52);
	transition: box-shadow 300ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
	border-radius: 8px;
	box-shadow: rgb(93 104 125 / 6%) 0px 0px 9px, rgb(93 104 125 / 3%) 0px 0px 1px;
	background-image: none;
	overflow: hidden;
	width: 50%;
	margin-left: 20%;
	margin-top: 10%;
	text-align: center;
}

.tishi {
	height: 100px;
	line-height: 100px;
	font-size: 25px;
}
</style>