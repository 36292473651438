<template>
  <div v-loading="show" class="pendingList-wrapper">
    <el-button @click="dialogVisible = true">{{ $t("CreateList") }}</el-button>
    <el-button @click="dialogVisible_zy = true">{{ $t("ChangeList") }}</el-button>
    <el-button  >
		 <a :href="'#/wlogin?whatsapp='+qyid+'&pageid='+qyid">{{ $t("importmyaddress") }}</a> 
	
	</el-button>

    <el-table
      :data="
        tableData.slice((currentPage - 1) * pageSize, currentPage * pageSize)
      "
      stripe
      style="width: 100%"
    >
	<el-table-column
	prop="addtime"
	:label="$t('date')"
	 >
		<template slot-scope="scope">
	         <div>{{scope.row.addtime | formatDate}} </div>
	       </template>
	</el-table-column>
      <el-table-column prop="title" :label="$t('listName')">
      </el-table-column>
    <!--  <el-table-column prop="filepath" :label="$t('templatePath')" width="380">
      </el-table-column> -->
      <el-table-column
        <el-table-column
        prop="daifanum"
        :label="$t('templatenum')"
      
      >
	  <template slot-scope="scope">
	    <div   @click="getlist_daifaid(scope.row.id)">
	       {{scope.row.daifanum}}
	    </div>
	  </template>
	  
      </el-table-column>
      <el-table-column :label="$t('status')">
        <template slot-scope="scope">
          <div>
            {{ scope.row.status == "0" ? "待审核" : "已通过" }}{{ scope[0] }}
          </div>
        </template>
      </el-table-column>

      <el-table-column prop="operation" label="操作" align="center" width="180">
        <template slot-scope="scope">
          <div class="operationVid">
            <span
              class="operationBef"
              @click="delitem(scope.row.id)"      
              >删除</span>
          </div>
        </template>
      </el-table-column>
<!--      <el-table-column prop="operation" label="操作" align="center" width="180">
        <template slot-scope="scope">
          <div class="operationVid">
            <span
              class="operationBef"
              @click="senditem(scope.row)"
             
              >创建发送任务</span>
          

 
          </div>
        </template>
      </el-table-column> -->
    </el-table>
    <div class="pagination flex-center">
      <el-pagination
        background
        @current-change="handleCurrentChange"
        :current-page="currentPage"
        :page-size="pageSize"
        layout="total,prev,pager,next"
        :total="tableData.length"
      ></el-pagination>
    </div>

    <!-- add -->
    <el-dialog
      :title="$t('addTemplate')"
      :visible.sync="dialogVisible"
      :width="$store.state.isMobile? '90%' :'556px'"
      :before-close="handleClose"
    >
      <div class="add-template">
        <el-form ref="form" :model="form"> 
		 
		 <el-form-item :label="$t('ziyuanType')">
			      <el-select v-model="type" placeholder="">
			          <el-option :label="$t('typewhatsapp')" value="whatsapp"></el-option>
			      <!--    <el-option :label="$t('typesms')" value="sms"></el-option>
			          <el-option :label="$t('typeEmail')" value="email"></el-option> -->
			      </el-select>
			  </el-form-item>
          <el-form-item :label="$t('listName')">
			
			  
			  
            <el-input v-model="title" type="textarea" :rows="2"></el-input>
          </el-form-item>

          <el-form-item label="上传文件:" prop="excel">
            <el-upload
              class="upload-demo"
              ref="upload"
              action
              :http-request="httpRequest"
              :before-upload="beforeUpload"
              :on-exceed="handleExceed"
              :limit="1"
            >
              <el-button slot="trigger" size="small" type="primary"
                >選取文件</el-button
              >
              <div slot="tip" class="el-upload__tip">
                只能上傳.xlsx文件，且不超過5M
              </div>      
			   <div slot="tip" class="el-upload__tip">
                <a href="http://w7.khshop.online/addons/qicheng_messenger/temp.xlsx">下載模板</a>
              </div>
            </el-upload>
          </el-form-item>
        </el-form>
      </div>
      <span slot="footer" class="dialog-footer">
        <el-button @click="dialogVisible = false">{{ $t("Cancel") }}</el-button>
        <el-button type="primary" @click="submitImportForm">{{
          $t("Sure")
        }}</el-button>
      </span>
    </el-dialog>
	
	<!-- add renwu-->
	<el-dialog
	  :title="$t('addRenwu')+nowfileid"
	  :visible.sync="dialogVisible_rw"
	  width="556px"
	  :before-close="handleClose_rw"
	>
	  <div class="add-template">
	    <el-form ref="form" :model="form">
			<el-form-item :label="$t('listName')">
			  <el-input v-model="form.title" type="textarea" :rows="2"></el-input>
			</el-form-item>
			
	      <el-form-item :label="$t('ziyuanList')">
	          <el-radio   :label="nowfiletitle"> </el-radio>
	      </el-form-item>  
		   
		   <el-form-item :label="$t('addRenwutime')">
	     <el-date-picker
	           v-model="form.plantime"
	           type="datetime"
	           placeholder="选择日期时间">
	         </el-date-picker>
	      </el-form-item>
	
	      <el-form-item label="选择模板" prop="excel">
	      
		    <el-select v-model="form.templateid" placeholder="请选择">
		      <el-option
		        v-for="item in templistData"
		        :key="item.id"
		        :label="item.title"
		        :value="item.id">
		      </el-option>
		    </el-select>
	      </el-form-item>
	    </el-form>
	  </div>
	  <span slot="footer" class="dialog-footer">
	    <el-button @click="dialogVisible_rw = false">{{ $t("Cancel") }}</el-button>
	    <el-button type="primary" @click="sure">{{
	      $t("Sure")
	    }}</el-button>
	  </span>
	</el-dialog>
	
	<!-- add renwu资源转换对话框-->
	<el-dialog
	  :title="$t('addzytitle')"
	  :visible.sync="dialogVisible_zy"
	  width="556px"
	  :before-close="handleClose_zy"
	>
	  <div class="add-template">
	    <el-form ref="form" :model="form">
			<el-form-item :label="$t('listName')">
			  <el-input v-model="form.title" type="textarea" :rows="1"></el-input>
			</el-form-item>
		 
		   <el-form-item :label="$t('zybegingtime')">
	     <el-date-picker
	           v-model="form.zybegintime"
	           type="datetime"
	           placeholder="选择日期时间">
	         </el-date-picker>
	      </el-form-item>
	 
		   <el-form-item :label="$t('zyendtime')">
	     <el-date-picker @change="sure_zy_count"
	           v-model="form.zyendtime"
	           type="datetime"
	           placeholder="选择日期时间">
	         </el-date-picker>
	      </el-form-item>
	 
	    </el-form>
	  </div>
	  <span slot="footer" class="dialog-footer">
	    <el-button @click="dialogVisible_zy = false">{{ $t("Cancel") }}</el-button>
	    <el-button  v-if="!caninsert" type="primary" disabled @click="sure_zy">{{
	      $t("Sure")
	    }}</el-button>
	    <el-button  v-if="caninsert" type="primary"  @click="sure_zy">{{
	      $t("Sure")
	    }}</el-button>
	  </span>
	</el-dialog>
	
	<!-- add -->
	<el-dialog
	  :title="$t('MobileList')"
	  :visible.sync="dialogVisible_list"
    :width="$store.state.isMobile? '90%' :'556px'"
	  :before-close="handleClose"
	>
	  <div class="add-template">
		  <el-table
		    :data="
		      daifalistData.slice((currentPage - 1) * pageSize, currentPage * pageSize)
		    "
		    stripe
		    style="width: 100%"
		  >
		    <el-table-column prop="nickname" :label="$t('nickName')"  >
		    </el-table-column>
			
			  <el-table-column prop="whatsapp" :label="$t('whatsapp')"  >
		    </el-table-column>
			
		  </el-table>
		  
		  <div class="pagination flex-center">
		    <el-pagination
		      background
		      @current-change="handleCurrentChange"
		      :current-page="currentPage"
		      :page-size="pageSize"
		      layout="total,prev,pager,next"
		      :total="daifalistData.length"
		    ></el-pagination>
		  </div>
		  
	</div>  
	</el-dialog>
		  
  </div>
</template>
<script>
	
	import { formatDate } from '../../assets/date.js'
	import axios from 'axios'
	import request from "@/utils/request";
	import { tongyong,addRenwu,addDaifa,addRenwu_zy } from "@/api";

export default {
  data() {
    return {
		 
		caninsert:false,
		show:false,
		  plantime: '',
		  templateid: '',
		title:'',
		type:'whatsapp',
      fileList: [],
      templistData: [],
      daifalistData: [],
      currentPage: 1, // 当前页码
      pageSize: 10, // 每页显示的行数
      activeName: "0",
      dialogVisible: false,
      dialogVisible_rw: false,
      dialogVisible_zy: false,
      dialogVisible_list: false,
      tableData: [
      
      ],
	  nowfileid:-1,
	  nowfiletitle:'',
      form: {
		  type:'whatsapp',
        title: "",
        qyid: "",
        daifaid: "",
        templateid: '',
        plantime: '',
        zybegintime: '',
       
        zyendtime: '',
        status: 0,
      },
	    options: [{
	            value: '选项1',
	            label: '黄金糕'
	          }],
	          value: ''
    };
  },
  filters: {
    formatDate: function (time) {
            time = time * 1000     //注意：time应为格式为13位unix时间戳，如果是10位的unix时间戳，需要乘以1000
                     let date = new Date(time)
                     return formatDate(date, 'yyyy-MM-dd hh:mm:ss')
      } 
    },
  created() {
	  var user = JSON.parse(window.localStorage.getItem("userinfo"));
	  console.log(user)
	  this.qyid = user.id 
    this.getlist();
  },

  methods: {
    // 页面切换方法
    handleCurrentChange(val) {
      this.currentPage = val;
    },
    handleClose() {
      this.dialogVisible = false;
      this.dialogVisible_rw = false;
      this.dialogVisible_list = false;
    },
    handleClose_rw() {
      this.dialogVisible_rw = false;
	  this.dialogVisible_zy = false;
	  this.dialogVisible_list = false;
    },
    handleClose_zy() {
      this.dialogVisible_rw = false;
	  this.dialogVisible_zy = false;
	  this.dialogVisible_list = false;
    },
    handlePreview() {},
    handleRemove() {},
    beforeRemove() {},
    handleExceed() {},
    // 添加模版点击确定
    sure() {
      var user = JSON.parse(window.localStorage.getItem("userinfo"));
      console.log(user)
    
	  if(!this.form.title) {
	  		    this.$message.error("请输入title");
	  		   return
	  }
	  if(this.form.plantime==0) {
	  		    this.$message.error("请选择时间");
	  		   return
	  }
      
      this.form.qyid= user.id
      this.form.daifaid= this.nowfileid
     
     
      this.form.status    = 0
	  
      
      addRenwu(this.form).then((res) => {
        if (res.code == 1) {
          this.$message({
            type: "success",
            message: res.msg,
          });
          this.dialogVisible_rw = false;
          //this.getlist();
        } else {
          this.$message({
            type: "error",
            message: res.msg,
          });
        }
      });
    },
    // 添加模版点击确定
    sure_zy() {
      var user = JSON.parse(window.localStorage.getItem("userinfo"));
 
	  if(!this.form.title) {
	  		    this.$message.error("请输入title");
	  		   return
	  }
	  if(this.form.zybegintime==0) {
	  		    this.$message.error("请选择时间");
	  		   return
	  }
      
      this.form.qyid= user.id
 
     
      this.form.status    = 0
      this.form.action    = 'insert'
	  
      
      addRenwu_zy(this.form).then((res) => {
		console.log(res);
        if (res.code == 1) {
          this.$message({
            type: "success",
            message: res.msg,
          });
          this.dialogVisible_zy = false;
          this.getlist();
        } else {
          this.$message({
            type: "error",
            message: res.msg,
          });
        }
      });
    },
    // 添加模版 tongji 
    sure_zy_count() {
      var user = JSON.parse(window.localStorage.getItem("userinfo"));
 
	 
	  if(this.form.zybegintime==0) {
	  		    this.$message.error("请选择时间");
	  		   return
	  }
      
      this.form.qyid= user.id
 
     
      this.form.status    = 0
      this.form.action    = 'count'
	  
      
      addRenwu_zy(this.form).then((res) => {
		console.log(res);
        if (res.code == 1) {
          this.$message({
            type: "success",
            message: '找到'+res.count +'条数据',
          });
		  if(res.count>0){this.caninsert = true}
        //  this.dialogVisible_zy = false;
          //this.getlist();
        } else {
          this.$message({
            type: "error",
            message: res.msg,
          });
        }
      });
    },
    // 我的模板列表
    getlist() {
		this.show=true
      console.log("go list");
      var user = JSON.parse(window.localStorage.getItem("userinfo"));

      let fdata = {
        sjk: "messenger_daifafile",
        action: "list",

        qyid: user.id,
        };
      tongyong(fdata).then((res) => {
        if (res.code == 1) {
          console.log(res);
          this.tableData = res.data;
        } else {
          this.$message({
            type: "error",
            message: res.msg,
          });
        }
		this.show=false
      });
    },
	
	
	 getlist_temp() {
      console.log("go messenger_templatelist");
      var user = JSON.parse(window.localStorage.getItem("userinfo"));

      let fdata = {
        sjk: "messenger_templatelist",
        action: "list",
		status:1,
		moresearch:'status,',
        qyid: user.id,
        };
      tongyong(fdata).then((res) => {
        if (res.code == 1) {
          console.log(res);
          this.templistData = res.data;
        } else {
          this.$message({
            type: "error",
            message: res.msg,
          });
        }
      });
    },
	
	
  getlist_daifaid(daifaid) {
	  this.dialogVisible_list=true 
      console.log("go messenger_templatelist");
      var user = JSON.parse(window.localStorage.getItem("userinfo"));
//&moresearch=daifaid,&daifaid=6
      let fdata = {
        sjk: "messenger_daifalist",
        action: "list",
        moresearch: "daifaid",
        daifaid: daifaid,

        qyid: user.id,
        };
      tongyong(fdata).then((res) => {
        if (res.code == 1) {
          console.log(res);
          this.daifalistData = res.data;
        } else {
          this.$message({
            type: "error",
            message: res.msg,
          });
        }
      });
    },
	
	
    httpRequest(option) {
      this.fileList.push(option);
    },
    // 上传前处理
    beforeUpload(file) {
      let fileSize = file.size;
      const FIVE_M = 5 * 1024 * 1024;
      //大于5M，不允许上传
      if (fileSize > FIVE_M) {
        this.$message.error("最大上传5M");
        return false;
      }
      //判断文件类型，必须是xlsx格式
      let fileName = file.name;
      let reg = /^.+(\.xlsx)$/;
      if (!reg.test(fileName)) {
        this.$message.error("只能上传xlsx!");
        return false;
      }
      return true;
    },
    // 文件数量过多时提醒
    handleExceed() {
      this.$message({ type: "error", message: "最多支持1个附件上传" });
    },
	
    //导入Excel病种信息数据
    submitImportForm() {
		var user = JSON.parse(window.localStorage.getItem("userinfo"));
		console.log(user);
	   
	   if(!this.title) {
		    this.$message.error("请输入title");
		   return
	   }
	   if(this.fileList.length==0) {
		    this.$message.error("请选择文件");
		   return
	   }
	 
      let paramsData = new FormData();
      // 将上传文件数组依次添加到参数paramsData中
      console.log("filelist", this.fileList);
	  
      this.fileList.forEach((x) => {
        console.log(x.file);
        paramsData.append("file", x.file);
      });
      // 将输入表单数据添加到params表单中
      paramsData.append("kgCode", "sss");
      paramsData.append("qyid",  user.id);
      paramsData.append("title",  this.title);
      paramsData.append("type",  this.type);
 
 
      //这里根据自己封装的axios来进行调用后端接口
      console.log("paramsData", paramsData.get("file"));

 	   axios.post("/app/index.php?i=1&c=entry&m=qicheng_messenger&do=api_daifa",paramsData).then(res=>{
                    console.log(res.data);
					
					if (res.data.code == 1) {
					  this.$message({
					    type: "success",
					    message: res.data.msg,
					  });
					   this.getlist()
					} else {
					  this.$message({
					    type: "error",
					    message: res.data.msg,
					  });
					}
					 this.dialogVisible = false//关闭对话框
					
                })
     // this.$refs.importFormRef.resetFields()//清除表单信息
      this.$refs.upload.clearFiles()//清空上传列表
      this.fileList = []//集合清空
	  this.title = ''
     
	
 
    },
	
	senditem(data){
		 this.getlist_temp()
		 this.nowfileid=data.id
		 console.log(this.nowfileid);
		 this.nowfiletitle = data.title
		 console.log(this.nowfiletitle);
		 this.dialogVisible_rw=true
	},
	//删除某个
	delitem(id){
		    this.$confirm('您确定删除吗？').then(_ => {
		
		    console.log('del..');
			let  fdata= {
					sjk: 'messenger_daifafile',
					action:'del',
					id:id
				}
			tongyong(fdata).then(res => {
				if(res.code==1){
				  console.log(res);
				  this.getlist()
				}else{
					this.$message({
						type: 'error',
						message: res.msg
					})
				}    
			})
		}).catch(_ => {
		
		　 
		
		})
		
		
	},
  },

};
</script>
<style lang="scss" scoped>
.pendingList-wrapper {
}
.pagination {
  width: 100%;
  margin: 30px auto;
}
::v-deep .el-select {
  width: 100%;
}
</style>