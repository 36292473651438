<template>
    <div class="recharge-record-wrapper">
            <el-table
                :data="tableData"
                stripe
                style="width: 100%">
               <el-table-column
               prop="addtime"
               :label="$t('date')"
               width="180">
               <template slot-scope="scope">
                        <div>{{scope.row.addtime | formatDate}} </div>
                      </template>
               </el-table-column>
                <el-table-column
                prop="filepath"
                :label="$t('invoice')"
                width="180">
                </el-table-column>
                <el-table-column
                prop="amount"
                :label="$t('money')">
                </el-table-column>
                <el-table-column>
                <template slot-scope="scope">
                    <el-button @click="handleClick(scope.row)" type="text" size="small">查看</el-button>
                </template>
    </el-table-column>
            </el-table>
           <div class="pagination flex-center">
                   <el-pagination background  @current-change="handleCurrentChange"
           :current-page="currentPage"
           :page-size="pageSize"   layout="total,prev,pager,next" :total="tableData.length"></el-pagination>
               </div>
    </div>
</template>
<script>
	import {tongyong} from '@/api'
	import { formatDate } from '../../assets/date.js'
export default {
    data() {
        return {
			currentPage: 1,  // 当前页码
			 pageSize: 10,  // 每页显示的行数
            tableData: [
                {timer: '2018.3.1', invoice: '现金', money: '14'},
            
            ],
        }
    },
	created()
	   {
			   this.getlist()
		   },
		   filters: {
		     formatDate: function (time) {
		             time = time * 1000     //注意：time应为格式为13位unix时间戳，如果是10位的unix时间戳，需要乘以1000
		                      let date = new Date(time)
		                      return formatDate(date, 'yyyy-MM-dd hh:mm:ss')
		       } 
		     },
		   
    methods: {
       handleClick () {
           
       },
	   beforeHandleCommand(e){
	   	console.log(e);
	   },
	    // 页面切换方法
	     handleCurrentChange(val) {
	       this.currentPage = val;
	     },
	  
	   getlist(){
			console.log('go messenger_invoicelist');
			var user = JSON.parse(window.localStorage.getItem('userinfo'))
			let  fdata= {
					sjk: 'messenger_invoicelist',
					action:'list',
					// status:this.status,
					// moresearch:'status,',
					qyid:user.id
				}
			tongyong(fdata).then(res => {
				if(res.code==1){
				  console.log(res);
				  this.tableData=res.data
				}else{
					this.$message({
						type: 'error',
						message: res.msg
					})
				}    
			})
	   }
	   
	   
    }
}
</script>
<style lang="scss" scoped>
    .pagination{
        width: 100%;
        margin: 30px auto;
    }
    ::v-deep .el-select{
            width: 100%;
            
    }
  
</style>