<template>
    <div class="template-wrapper">
      <el-button @click="addrenwu">{{$t('addRenwu')}}</el-button>  
        <el-tabs v-model="activeName" @tab-click="handleClick">
            <el-tab-pane :label="item.label" :name="item.id" v-for="item in tab" :key="item.id"></el-tab-pane>
        </el-tabs>
        <el-table
            :data="tableData.slice((currentPage - 1) * pageSize, currentPage * pageSize)"
            stripe
            style="width: 100%">
            <el-table-column
            prop="plantime"
            :label="$t('plantime')"
            >
            </el-table-column>  
			<el-table-column
            prop="title"
            :label="$t('rwtitle')"
            >
            </el-table-column>
            <el-table-column
            prop="templatetitle"
            :label="$t('templateName')"
            >
            </el-table-column>        
			 <el-table-column
            prop="type"
            :label="$t('type')"
            >
            </el-table-column>   
			<el-table-column
            prop="daifatitle"
            :label="$t('daifatitle')"
            >
            </el-table-column>	
			<el-table-column
            prop="sendnum"
            :label="$t('sendnum')"
            >
            </el-table-column>
            <el-table-column
           
            :label="$t('status')">
			 <template slot-scope="scope">
				 <div>{{scope.row.msg}}</div>
			     <!--     <div>{{scope.row.status=='0'?$t('planing'):$t('ending')}}{{scope[0]}}</div> -->
			        </template>
            </el-table-column>
			
			  <el-table-column
			      prop="operation"
			      label="操作"
			      align="center"
			      >
			     <template slot-scope="scope">
			     <div class="operationVid">
			       <span class="operationBef" @click="delitem(scope.row.id)"  v-if="scope.row.status=='0'">删除</span>
			       <span   class="operationBef" @click="baogao(scope.row)"  v-if="scope.row.status=='1'">报告</span>
			    
				<!-- <el-dropdown trigger="click"  >
			            <span class="el-dropdown-link">
			              操作<i class="el-icon-arrow-down el-icon--right"></i>
			            </span>
			            <el-dropdown-menu slot="dropdown">
			                <el-dropdown-item :command="beforeHandleCommand(scope,'订单详情')" >删除</el-dropdown-item>
			           </el-dropdown-menu>
			        </el-dropdown> -->
			    </div>
			     </template>
			    </el-table-column>
		 
        </el-table>
        <div class="pagination flex-center">
            <el-pagination background  @current-change="handleCurrentChange"
    :current-page="currentPage"
    :page-size="pageSize"   layout="total,prev,pager,next" :total="tableData.length"></el-pagination>
        </div>
   <!-- add renwu-->
   <el-dialog
     :title="$t('addRenwu')"
     :visible.sync="dialogVisible"
     :width="$store.state.isMobile? '90%' :'556px'"
     :before-close="handleClose"
   >
     <div class="add-template">
       <el-form ref="form" :model="form">
		  
		  
		  <el-form-item :label="$t('addRenwutime')">
		   <el-date-picker
		         v-model="form.plantime"
		         type="datetime"
		         placeholder="选择日期时间">
		       </el-date-picker>
		    </el-form-item>
		     
			  
		   <el-form-item :label="$t('renwuType')">
		       <el-select @change="changetype" v-model="form.type" placeholder="">
		           <el-option :label="$t('typewhatsapp')" value="whatsapp"></el-option>
		           <el-option :label="$t('businessApi')" value="businessApi"></el-option>
		         <!--  <el-option :label="$t('typesms')" value="sms"></el-option>
		           <el-option :label="$t('typeEmail')" value="email"></el-option> -->
		       </el-select>
		   </el-form-item>
		   
		   
		   
   		<el-form-item :label="$t('listName')">
   		  <el-input v-model="form.title"   :rows="1"></el-input>
   		</el-form-item>
   		
  

  
     <el-form-item label="选择资源" prop="excel">
         
   	    <el-select v-model="form.daifaid" placeholder="请选择">
   	      <el-option
   	        v-for="item in daifalistData"
   	        :key="item.id"
   	        :label="item.title"
   	        :value="item.id">
   	      </el-option>
   	    </el-select>   
		    </el-form-item>	
			
      <el-form-item v-if="form.type=='whatsapp'" label="选择模板" prop="excel">
         
   	    <el-select v-model="form.templateid" placeholder="请选择">
   	      <el-option
   	        v-for="item in templistData"
   	        :key="item.id"
   	        :label="item.title"
   	        :value="item.id">
   	      </el-option>
   	    </el-select>
         </el-form-item>
		 
		 
			
      <el-form-item v-if="form.type=='businessApi'" label="选择模板" prop="excel">
         
   	    <el-select @change="schange" v-model="form.templateid" placeholder="请选择">
   	      <el-option
   	        v-for="item in gflist"
   	        :key="item.id"
   	        :label="item.name"
   	        :value="item.name">
   	      </el-option>
   	    </el-select>
		<span v-if="gftmplist[form.templateid]"> {{gftmplist[form.templateid]['components'][0]['text'] }} {{gftmplist[form.templateid]['components'][1]['text'] }} </span>
         </el-form-item>
		<el-form-item v-if="form.type=='businessApi'" :label="$t('head_img')">
		  <el-input v-model="form.head_img"   :rows="1"></el-input>
		</el-form-item>
		 
 		 
		 <el-form-item   v-if="haveone" label="内容">
		   <el-input v-model="form.msg1"   :rows="2"></el-input>
		 </el-form-item>
 
		 <el-form-item   v-if="havetwo" 	label="内容">
		   <el-input v-model="form.msg2"   :rows="2"></el-input>
		 </el-form-item>
 
		 
       </el-form>
     </div>
     <span slot="footer" class="dialog-footer">
       <el-button @click="dialogVisible = false">{{ $t("Cancel") }}</el-button>
       <el-button type="primary" @click="sure">{{
         $t("Sure")
       }}</el-button>
     </span>
   </el-dialog>
   
    </div>
</template>
<script>
import {tongyong,addRenwu,funk} from '@/api'
import {addTemplate} from '@/api'
export default {
    data() {
        return {
			daifalistData:[],
			type:'whatsapp',
			templistData:[],
			 currentPage: 1,  // 当前页码
			  pageSize: 10,  // 每页显示的行数
            activeName: '0',
            dialogVisible: false,
            tab: [
                {label: this.$t('planing'), id: '0'},
                {label: this.$t('ending'), id: '1'},
                {label: this.$t('failing'), id: '-1'}
            ],
			tableData:[],
			gflist:[],
			gftmplist:[],
        
			status:0,
            form: {
                type: this.$t('typewhatsapp'),
                title: '',
                text: '',
                qyid: '',
                status: '',
                pic: ''
            },
			haveone:false,
			havetwo:false
        }
    },
	created() {
			   this.gettemplist()
			   this.getgflist()
			  
			  },
    methods: {
		schange(e,item){
			this.haveone = false
			this.havetwo = false 
			this.form.language = this.gftmplist[e]['language']
			console.log('this.form.language',this.form.language);
		    var tmp = this.gftmplist[e]['components'][0]['text']
			console.log('tmp1',tmp);
				if(tmp != undefined){	
					if( tmp.indexOf('{{') >0){
						console.log('have one ');
						this.haveone=true 
					}
			}
			if(this.gftmplist[e]['components'][1]){
				 tmp = this.gftmplist[e]['components'][1]['text']
				 	console.log('tmp2',tmp);
				if(tmp != undefined){	
					if( tmp.indexOf('{{') >0){
						
						if(this.haveone){
							console.log('have one . ');
							this.havetwo=true;
						}else{
								console.log('have one ');
							this.haveone = true 
						}
					}
				}
			}
			
		},
		uptime(){
			let year = new Date().getFullYear();
			//月份是从0月开始获取的，所以要+1;
			let month = new Date().getMonth() +1;
			//日
			let day = new Date().getDate();
			//时
			let hour = new Date().getHours();
			//分
			let minute = new Date().getMinutes()+2;
			//秒
			let second = new Date().getSeconds();
							
			let time = year + '-' + month + '-' + day + ' ' + hour +':'+minute+':'+second
			
		//	this.form.plantime=time
		},
		changetype(e){
			console.log(e);
			this.type=e
			this.getlist_temp()
			this.getdaifalist()
		},
		addrenwu(){
			this.uptime()
			this.dialogVisible=true;
			this.getlist_temp()
			this.getdaifalist()
		},
		beforeHandleCommand(e){
			console.log(e);
		},
		 // 页面切换方法
		  handleCurrentChange(val) {
		    this.currentPage = val;
		  },
        handleClick(tab, event) {
            console.log(tab.index);
			this.status=tab.index
			if(this.status==2){this.status=-1}
			this.currentPage=1
			 this.gettemplist()
        },
        handleClose() {
            this.dialogVisible = !this.dialogVisible
        },
        handlePictureCardPreview() {

        },
        handleRemove() {

        },
        // 添加模版点击确定
        sure() {
			 var user = JSON.parse(window.localStorage.getItem("userinfo"));
			 console.log(user)
			     
			 if(!this.form.title) {
			 		    this.$message.error("请输入title");
			 		   return
			 }
			 if(this.form.plantime==0) {
			 		    this.$message.error("请选择时间");
			 		   return
			 }
			 
			 this.form.qyid= user.id
		 
			      
			      
			 this.form.status    = 0
			 this.form.msg    = 'ready to send'
			 
			 
			 addRenwu(this.form).then((res) => {
			   if (res.code == 1) {
			     this.$message({
			       type: "success",
			       message: res.msg,
			     });
			     this.dialogVisible= false;
			     this.gettemplist();
			   } else {
			     this.$message({
			       type: "error",
			       message: res.msg,
			     });
			   }
			 });
        },
		baogao(item){
			let url='https://w7.khshop.online/app/index.php?i=1&c=entry&m=qicheng_messenger&do=api_tongyong&sjk=messenger_sendlist&action=list&moresearch=rwid&ex=xls&rwid='+item.id+'&addtime='+item.addtime
		    window.open(url)
		},
		//删除某个
		delitem(id){
			this.$confirm('您确定删除吗？').then(_ => {
			
			    console.log('del..');
				let  fdata= {
						sjk: 'messenger_sendrwlist',
						action:'del',
						id:id
					}
				tongyong(fdata).then(res => {
					if(res.code==1){
					  console.log(res);
					  this.gettemplist()
					}else{
						this.$message({
							type: 'error',
							message: res.msg
						})
					}    
				})
			}).catch(_ => {
			
			　 
			
			})
			
			
		},
		// 我的任务列表
		gettemplist(){
			console.log('go taocan');
			var user = JSON.parse(window.localStorage.getItem('userinfo'))
		 
					 
			 
			let  fdata= {
					sjk: 'messenger_sendrwlist',
					action:'list',
					status:this.status,
					moresearch:'status,',
					qyid:user.id
				}
			tongyong(fdata).then(res => {
				if(res.code==1){
				  console.log(res);
				  this.tableData=res.data
				}else{
					this.$message({
						type: 'error',
						message: res.msg
					})
				}    
			})
		},
		
		// 官方模板列表
		getgflist(){
			console.log('go guanfang tmeplate');
			var user = JSON.parse(window.localStorage.getItem('userinfo'))
		
			let  fdata= {
					sjk: 'messenger_templatelist',
					action:'list',
					status:this.status,
					moresearch:'status,',
					qyid:user.id,
					do:'whats_template'
				}
			funk(fdata).then(res => {
				if(res.data.length>0){
				 
				  this.gflist=res.data
				  this.gftmplist=res.mdata
				   console.log('gflist',this.gflist);
				   console.log('gftmplist',this.gftmplist);
				}else{
					this.$message({
						type: 'error',
						message: res.msg
					})
				}    
			})
		},
		
		
		// 我的代发列表
		getdaifalist() {
		  console.log("go taocan");
		  var user = JSON.parse(window.localStorage.getItem("userinfo"));
		
		  let fdata = {
		    sjk: "messenger_daifafile",
		    action: "list",
			// type:this.type,
			// moresearch:'type,',
		    qyid: user.id,
		    };
		  tongyong(fdata).then((res) => {
		    if (res.code == 1) {
		      console.log(res);
		      this.daifalistData = res.data;
		    } else {
		      this.$message({
		        type: "error",
		        message: res.msg,
		      });
		    }
		  });
		},
		
		//模板列表
		 getlist_temp() {
		  console.log("go messenger_templatelist");
		  var user = JSON.parse(window.localStorage.getItem("userinfo"));
		
		  let fdata = {
		    sjk: "messenger_templatelist",
		    action: "list",
			status:1,
		
			moresearch:'status,',
		    qyid: user.id,
		    };
		  tongyong(fdata).then((res) => {
		    if (res.code == 1) {
		      console.log(res);
		      this.templistData = res.data;
		    } else {
		      this.$message({
		        type: "error",
		        message: res.msg,
		      });
		    }
		  });
		},
		
		
		
    }
}
</script>
<style lang="scss" scoped>
    .pagination{
        width: 100%;
        margin: 30px auto;
    }
    ::v-deep .el-select{
            width: 100%;   
    }
	@media screen and (max-width: 768px){
		
		::v-deep .el-form-item{
			margin-bottom: 0px;
		}
		::v-deep .el-dialog__body{
			padding: 10px 20px;
		}
    }
</style>