<template>
    <div class="template-wrapper">
        <el-button @click="addzuowei">{{$t('addSet')}}</el-button> 
   <!--     <el-tabs v-model="activeName" @tab-click="handleClick">
            <el-tab-pane :label="item.label" :name="item.id" v-for="item in tab" :key="item.id"></el-tab-pane>
        </el-tabs> -->
        <el-table
            :data="tableData.slice((currentPage - 1) * pageSize, currentPage * pageSize)"
            stripe
            style="width: 100%">
            <el-table-column v-for="item in sjkData"
				:prop="item.key"
				:label="item.title"
				>
				  <template   slot-scope="scope">
				  <div v-if="item.type=='choose'">{{scope.row[item.key]=='0'? item.optionst[0]:item.optionst[1]}}</div>
				  <div v-if="item.type!='choose'">{{scope.row[item.key]}} </div>
				  
				</template>  
            </el-table-column>
           <el-table-column
           prop="nownum"
           :label="$t('nownum')"
           width="">
           </el-table-column>
        <el-table-column
           prop="allnum"
           :label="$t('allnum')"
           width="">
           </el-table-column>
 
			  <el-table-column
			      prop="operation"
			      label="操作"
			      align="center"
			      width="180">
			     <template slot-scope="scope">
			     <div class="operationVid">
			       <span class="operationBef" @click="edititem(scope.row)"  >叫号</span>
			       <span style="width: 10px;" class="operationBef"  > </span >
			       <span class="operationBef" @click="delitem(scope.row.id)"  >重复叫号</span>
 
			    </div>
			     </template>
			    </el-table-column>
		 
        </el-table>
        <div class="pagination flex-center">
            <el-pagination background  @current-change="handleCurrentChange"
    :current-page="currentPage"
    :page-size="pageSize"   layout="total,prev,pager,next" :total="tableData.length"></el-pagination>
        </div>

       <!-- add -->
        <el-dialog :title="$t('addZuowei')" :visible.sync="dialogVisible" width="556px" :before-close="handleClose">
            <div class="add-template">
                <el-form ref="form" :model="form">

                    <el-form-item :label="$t('zuoweiType')">
                        <el-select v-model="form.zimu" placeholder="">
                            <el-option :label="$t('setA')" value="A"></el-option>
                            <el-option :label="$t('setB')" value="B"></el-option>
                            <el-option :label="$t('setC')" value="C"></el-option>
                            <el-option :label="$t('setD')" value="D"></el-option>
                        </el-select>
                    </el-form-item>

                    <el-form-item :label="$t('templateName')">
                        <el-input v-model="form.type"></el-input>
                    </el-form-item>

               

                    <el-form-item v-if="form.type == 2">
                        <img width="100%" :src="form.pic" alt="">
                        <el-upload
                            action="https://jsonplaceholder.typicode.com/posts/"
                            list-type="picture-card"
                            :on-preview="handlePictureCardPreview"
                            :on-remove="handleRemove">
                             <i class="el-icon-plus"></i>
                        </el-upload>
                    </el-form-item>
                </el-form>
            </div>
            <span slot="footer" class="dialog-footer">
                <el-button @click="dialogVisible = false">{{$t('Cancel')}}</el-button>
                <el-button type="primary" @click="sure">{{$t('Sure')}}</el-button>
            </span>
        </el-dialog>

    </div>
</template>
<script>
import {tongyong,sjk} from '@/api'
import {addPaiduiSet} from '@/api'
export default {
    data() {
        return {
			action:'',
			sjk:'messenger_paiduilist',
			currentPage: 1,  // 当前页码
			pageSize: 10,  // 每页显示的行数
            activeName: '0',
            dialogVisible: false,
            tab: [
                {label: this.$t('underApproval'), id: '0'},
                {label: this.$t('passed'), id: '1'}
            ],
			tableData:[],
			sjkData:[],
            tableData1: [
              
            ],
			status:0,
			  sform: {
				  parmas:{}
			  },
            form: {
                type: '1',
                zimu: '',
           
                qyid: '',
         
            },
        }
    },
	created(option) {
					if(this.$route.params.sjk){
						this.sjk=this.$route.params.sjk
					}
			
					console.log('sjk',this.sjk);
					if(this.sjk){
					   this.getsjk()
					   this.gettemplist()
				   }
			  },
    methods: {
		addzuowei(){
			 this.dialogVisible=true
			 this.action='insert'
			 this.form= {type: '1',zimu: '',qyid: ''}
		},
		beforeHandleCommand(e){
			console.log(e);
		},
		 // 页面切换方法
		  handleCurrentChange(val) {
		    this.currentPage = val;
		  },
        handleClick(tab, event) {
            console.log(tab.index);
			this.status=tab.index
			this.currentPage=1
			 this.gettemplist()
        },
        handleClose() {
            this.dialogVisible = !this.dialogVisible
        },
        handlePictureCardPreview() {

        },
        handleRemove() {

        },
        // 添加模版点击确定
        sure() {
			 var user = JSON.parse(window.localStorage.getItem('userinfo'))
			 console.log(user);
		 
			 this.form.qyid= user.id
			 this.form.action= this.action
			  let data = JSON.stringify(this.form);
			 
			 this.sform.parmas= data
			 
            addPaiduiSet(this.sform).then(res => {
				console.log(res);
                if(res.code==1){
                    this.$message({
                        type: 'success',
                        message: res.msg
                    })
                    this.dialogVisible = false
					  this.gettemplist()
                }else{
                    this.$message({
                        type: 'error',
                        message: res.msg
                    })
                }    
            })
        },
		edititem(data){
			this.dialogVisible=true
			this.action='edit'
			this.form = data 
		},
		//删除某个
		delitem(id){
			this.$confirm('您确定删除吗？').then(_ => {
			
			    console.log('del..');
				let  fdata= {
						sjk: this.sjk,
						action:'del',
						id:id
					}
				tongyong(fdata).then(res => {
					if(res.code==1){
					  console.log(res);
					  this.gettemplist()
					}else{
						this.$message({
							type: 'error',
							message: res.msg
						})
					}    
				})
			}).catch(_ => {
			
			　 
			
			})
			
			
		},
		// 我的模板列表
		gettemplist(){
			
			var user = JSON.parse(window.localStorage.getItem('userinfo'))
 
			let  fdata= {
					sjk: this.sjk,
					action:'list',
					// status:this.status,
					// moresearch:'status,',
					qyid:user.id
				}
				console.log('go taocan',fdata);
			tongyong(fdata).then(res => {
				if(res.code==1){
				  console.log(res);
				  this.tableData=res.data
				}else{
					this.$message({
						type: 'error',
						message: res.msg
					})
				}    
			})
		},
		// 读取数据库结构
		getsjk(){
			console.log('go sjk');
	 
			let  fdata= {
					sjk: this.sjk,
				
				}
			sjk(fdata).then(res => {
				if(res.code==1){
				  console.log('sjk',res);
				  this.sjkData=res.data
				}else{
					this.$message({
						type: 'error',
						message: res.msg
					})
				}    
			})
		},
		
    }
}
</script>
<style lang="scss" scoped>
    .pagination{
        width: 100%;
        margin: 30px auto;
    }
	.operationBef{
		margin: 10px;
	}
    ::v-deep .el-select{
            width: 100%;
            
    }
  
</style>