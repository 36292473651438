<template>
    <div class="home-wrapper">
		<!-- <el-button @click="shouquan">{{$t('shouquan')}}</el-button> -->
		 <el-button v-if="tableData.length==0" @click="dialogVisible=true">{{$t('adddianpu')}}</el-button>
		 <div style="height: 10px;"></div>
        <el-row :gutter="12">
            <el-col  :span="8" v-for="item in tableData" :key="item.label">
				 <img style="width: 100%;;" :src="item.logo"/>
                <el-card shadow="always">
					<div class="label">营业中</div>
						<div class="value">{{item.name}}</div>
						<div class="label"> 
							<div class="label"> <el-button><a target="_blank" style="flex:1" :href="'http://show.nowaittech.com/#/home?pageid='+item.pageid+'&qyid='+qyid">{{$t('Show Panel')}}</a> </el-button> </div>
							<div class="label">  <el-button  @click="setting(item)"> Set</el-button> </div>
							<div class="label">  <el-button  @click="gotopage(item.pageid)"> 进入</el-button></div>
					 </div>
					
						
					 
				</el-card>
				 
            </el-col> 	
        </el-row>
		 
		 
		 <!-- add -->
	<!-- add renwu-->
	<el-dialog
	  :title="$t('addDianpu')"
	  :visible.sync="dialogVisible"
	  :width="$store.state.isMobile? '90%' :'556px'"
	  :before-close="handleClose"
	>
	  <div class="add-template">
	    <el-form ref="form" :model="form">
			<el-form-item :label="$t('listName')">
			  <el-input v-model="form.name" type="textarea" :rows="2"></el-input>
			</el-form-item>
	    </el-form>
	  </div>
	  <span slot="footer" class="dialog-footer">
	    <el-button @click="dialogVisible = false">{{ $t("Cancel") }}</el-button>
	    <el-button type="primary" @click="addzuoxi">{{
	      $t("Sure")
	    }}</el-button>
	  </span>
	</el-dialog>
	
	<el-dialog :title="$t('edit')" :visible.sync="dialogVisible_key" width="556px" :before-close="handleClose">
	           <div class="add-template">
	               <el-form ref="form" :model="formkey">
	
	               <el-form-item :label="$t('name')">
	                       <el-input v-model="formkey.name"></el-input>
	                   </el-form-item>           
						   <el-form-item :label="$t('subname')">
	                       <el-input v-model="formkey.subname"></el-input>
	                   </el-form-item>   
						 <el-form-item :label="$t('pagewhatsapp')">
						 <span><a :href="'#/wlogin?whatsapp='+pageid+'&pageid='+pageid">扫码接入</a></span>
	                       <el-input v-model="formkey.pagewhatsapp"></el-input>
							 
	                   </el-form-item>				 
						<el-form-item :label="$t('businesswhatsapp')">
						 <span> 填写接入的businessAPI 号码</span>
	                       <el-input v-model="formkey.businesswhatsapp"></el-input>
							 
	                   </el-form-item>
						<el-form-item :label="$t('guangliyuanphone')">
						 <span> </span>
	                       <el-input v-model="formkey.guangliyuanphone"></el-input>
							 
	                   </el-form-item>			
					   
					   <el-form-item :label="$t('logo')">
						 <span> </span>
	                       <el-input v-model="formkey.logo"></el-input>
							 
	                   </el-form-item>
	              <!--    <el-form-item :label="$t('wd1')">
	                       <el-input v-model="formkey.wd1"></el-input>
	                   </el-form-item>
						<el-form-item :label="$t('wd2')">
	                       <el-input v-model="formkey.wd2"></el-input>
	                   </el-form-item>
						<el-form-item :label="$t('wd3')">
	                       <el-input v-model="formkey.wd3"></el-input>
	                   </el-form-item>
	-->
	             
	               </el-form>
	           </div>
	           <span slot="footer" class="dialog-footer">
	               <el-button @click="dialogVisible_key = false">{{$t('Cancel')}}</el-button>
	               <el-button type="primary" @click="surekey">{{$t('Sure')}}</el-button>
	           </span>
	       </el-dialog>
			
			
			
    </div>
	 
</template>
<script>
	import {tongji,tongyong,funk,api_tongyong_edit} from '@/api'
export default {
    data() {
        return {
			 value: new Date(),
			   dialogVisible: false,
			   dialogVisible_key:false,
			tableData:[],
			numberConfig: [
				{label: this.$t('todaytask'), value: '0'},
				{label: this.$t('numberOfTemplates'), value: '0'},
				// {label: this.$t('totalNumberOfCustomers'), value: '0'},
				{label: this.$t('balance'), value: '0'}
			] ,
			 form: {
			     name: '',
		 
			 },
			 sform: {
			 	 parmas:{}
			 },
			 qyid:'',
			 formkey:{
			 	keyword:'kkk'
			 }
		}
    },
	
	created()
	   {
		   var user = JSON.parse(window.localStorage.getItem('userinfo'))
		   this.qyid = user.id 
			   this.gettemplist()
			  
	   },
	methods: {
		setting(data){
			  this.dialogVisible_key = true;
			  this.formkey=data 
		},
		
		surekey() {
			 var user = JSON.parse(window.localStorage.getItem('userinfo'))
			 console.log(user);
		 
			 
			 this.formkey.sjk   = 'messenger_pagelist'
			 this.formkey.updatekey   = 'name,wd1,wd2,wd3,pagewhatsapp,subname,businesswhatsapp,logo,guangliyuanphone'
			 this.formkey.setkey   ='id' 
			 
			 
		 
			  let data = JSON.stringify(this.formkey);
			 
			  this.sform.parmas= data
			 
		    api_tongyong_edit(this.sform).then(res => {
				console.log(res);
		        if(res.code==1){
		            this.$message({
		                type: 'success',
		                message: res.msg
		            })
		            this.dialogVisible_key = false
					this.getpagedata()
		        }else{
		            this.$message({
		                type: 'error',
		                message: res.msg
		            })
		        }    
		    })
		},
		
		handleClose() {
		  this.dialogVisible = false;
		  this.dialogVisible_key = false;
		 
		},
 
		
		gotopage(pageid){
			this.$router.push('/waitset/'+pageid);
		},
		getUserinfo(){
			 var user = JSON.parse(window.localStorage.getItem('userinfo'))
			 let  fdata= {
			 		qyid:user.id
			 	}
			 getUserinfo(fdata).then(res => {
			 	if(res.code==1){
			 	  console.log(res);
				   window.localStorage.setItem('userinfo', JSON.stringify(res.data))
			 	  this.numberConfig[2].value=res.data.balance
			 	}else{
			 		this.$message({
			 			type: 'error',
			 			message: res.msg
			 		})
			 	}    
			 })
		},
		
	 
		
		gettemplist(){
			console.log('go taocan');
			var user = JSON.parse(window.localStorage.getItem('userinfo'))
		  
			let  fdata= {
					sjk: 'messenger_pagelist',
					action:'list',
				
					qyid:user.id
				}
			tongyong(fdata).then(res => {
				if(res.code==1){
				  console.log(res);
				  this.tableData=res.data
				}else{
					this.$message({
						type: 'error',
						message: res.msg
					})
				}    
			})
		},
		shouquan(){
			let url='https://w7.khshop.online/app/index.php?i=1&c=entry&m=qicheng_messenger&do=shouquan'
			window.open(url,'_Self')
		},
		addzuoxi(){
			console.log('go taocan');
			var user = JSON.parse(window.localStorage.getItem('userinfo'))
					  
			let  fdata= {
					do: 'api_dianpu_insert',
				    qyid:user.id,
					name:this.form.name,
				 
				
				}
				 
				funk(fdata).then(res => {
					if(res.code==1){
					  console.log(res);
					  this.gettemplist()
				 
					}else{
						this.$message({
							type: 'error',
							message: res.msg
						})
					}    
			})
			
			
		}
		
	 
			  }
		   
};
</script>
<style lang="scss" scoped>
	.home-wrapper{
		width: 100%;
		.label{
			font-size: 18px;
			font-weight: bold;
			color: rgba(0,0,0,.45);
			display: flex;
			.label{
				flex:1;
				font-size: 15px;
			}
		}
		.value{
			margin-top: 20px;
			text-align: center;
			width: 100%;
			height: 120px;
			font-weight: bold;
			font-size: 40px;
			color: #666;

		}
	}
</style>