<template>
    <div class="template-wrapper">
        <!-- <el-button @click="dialogVisible=true">{{$t('addTemplate')}}</el-button> -->
   <!--     <el-tabs v-model="activeName" @tab-click="handleClick">
            <el-tab-pane :label="item.label" :name="item.id" v-for="item in tab" :key="item.id"></el-tab-pane>
        </el-tabs> -->
        <el-table
            :data="tableData.slice((currentPage - 1) * pageSize, currentPage * pageSize)"
            stripe
            style="width: 100%">
			<el-table-column
			prop="addtime"
			:label="$t('date')"
			width="180">
			<template slot-scope="scope">
			         <div>{{scope.row.addtime | formatDate}} </div>
			       </template>
			</el-table-column>
            <el-table-column v-for="item in sjkData"
				:prop="item.key"
				:label="item.title"
				>
				  <template   slot-scope="scope">
				  <div v-if="item.type=='choose'">{{scope.row[item.key]=='0'? item.optionst[0]:item.optionst[1]}}</div>
				  <div v-if="item.type!='choose'">{{scope.row[item.key]}} </div>
				  
				</template>  
            </el-table-column>
           
 
			  <el-table-column
			      prop="operation"
			      label="操作"
			      align="center"
			      width="180">
			     <template slot-scope="scope">
			     <div class="operationVid">
			       <span class="operationBef" @click="delitem(scope.row.id)"  v-if="scope.row.status=='0'">删除</span>
			    
 
			    </div>
			     </template>
			    </el-table-column>
		 
        </el-table>
        <div class="pagination flex-center">
            <el-pagination background  @current-change="handleCurrentChange"
    :current-page="currentPage"
    :page-size="pageSize"   layout="total,prev,pager,next" :total="tableData.length"></el-pagination>
        </div>

       <!-- add -->
        <el-dialog :title="$t('addTemplate')" :visible.sync="dialogVisible" width="556px" :before-close="handleClose">
            <div class="add-template">
                <el-form ref="form" :model="form">

                    <el-form-item :label="$t('templateType')">
                        <el-select v-model="form.type" placeholder="">
                            <el-option :label="$t('textTemplate')" value="1"></el-option>
                            <el-option :label="$t('picturesAndText')" value="2"></el-option>
                            <el-option :label="$t('ComprehensiveTemplate')" value="3"></el-option>
                        </el-select>
                    </el-form-item>

                    <el-form-item :label="$t('templateName')">
                        <el-input v-model="form.title"></el-input>
                    </el-form-item>

                    <el-form-item :label="$t('TemplateText')">
                        <el-input v-model="form.detail" type="textarea" :rows="2"></el-input>
                    </el-form-item>

                    <el-form-item v-if="form.type == 2">
                        <img width="100%" :src="form.pic" alt="">
                        <el-upload
                            action="https://jsonplaceholder.typicode.com/posts/"
                            list-type="picture-card"
                            :on-preview="handlePictureCardPreview"
                            :on-remove="handleRemove">
                             <i class="el-icon-plus"></i>
                        </el-upload>
                    </el-form-item>
                </el-form>
            </div>
            <span slot="footer" class="dialog-footer">
                <el-button @click="dialogVisible = false">{{$t('Cancel')}}</el-button>
                <el-button type="primary" @click="sure">{{$t('Sure')}}</el-button>
            </span>
        </el-dialog>

    </div>
</template>
<script>
		import { formatDate } from '../../assets/date.js'
import {tongyong,sjk} from '@/api'
import {addTemplate} from '@/api'
export default {
    data() {
        return {
			sjk:'',
			currentPage: 1,  // 当前页码
			pageSize: 10,  // 每页显示的行数
            activeName: '0',
            dialogVisible: false,
            tab: [
                {label: this.$t('underApproval'), id: '0'},
                {label: this.$t('passed'), id: '1'}
            ],
			tableData:[],
			sjkData:[],
            tableData1: [
              
            ],
			status:0,
            form: {
                type: '1',
                title: '',
                text: '',
                qyid: '',
                status: '',
                pic: ''
            },
        }
    },
	filters: {
	  formatDate: function (time) {
	          time = time * 1000     //注意：time应为格式为13位unix时间戳，如果是10位的unix时间戳，需要乘以1000
	                   let date = new Date(time)
	                   return formatDate(date, 'yyyy-MM-dd hh:mm:ss')
	    } 
	  },
	created(option) {
					if(this.$route.params.sjk){
						this.sjk=this.$route.params.sjk
					}
			
					console.log('sjk',this.sjk);
					if(this.sjk){
					   this.getsjk()
					   this.gettemplist()
				   }
			  },
    methods: {
		beforeHandleCommand(e){
			console.log(e);
		},
		 // 页面切换方法
		  handleCurrentChange(val) {
		    this.currentPage = val;
		  },
        handleClick(tab, event) {
            console.log(tab.index);
			this.status=tab.index
			this.currentPage=1
			 this.gettemplist()
        },
        handleClose() {
            this.dialogVisible = !this.dialogVisible
        },
        handlePictureCardPreview() {

        },
        handleRemove() {

        },
        // 添加模版点击确定
        sure() {
			 var user = JSON.parse(window.localStorage.getItem('userinfo'))
			 console.log(user);
		 
			 this.form.qyid= user.id
			 this.form.status= 0
            addTemplate(this.form).then(res => {
                if(res.code==1){
                    this.$message({
                        type: 'success',
                        message: res.msg
                    })
                    this.dialogVisible = false
					  this.gettemplist()
                }else{
                    this.$message({
                        type: 'error',
                        message: res.msg
                    })
                }    
            })
        },
		//删除某个
		delitem(id){
			this.$confirm('您确定删除吗？').then(_ => {
			
			    console.log('del..');
				let  fdata= {
						sjk: this.sjk,
						action:'del',
						id:id
					}
				tongyong(fdata).then(res => {
					if(res.code==1){
					  console.log(res);
					  this.gettemplist()
					}else{
						this.$message({
							type: 'error',
							message: res.msg
						})
					}    
				})
			}).catch(_ => {
			
			　 
			
			})
			
			
		},
		// 我的模板列表
		gettemplist(){
			
			var user = JSON.parse(window.localStorage.getItem('userinfo'))
 
			let  fdata= {
					sjk: this.sjk,
					action:'list',
					// status:this.status,
					// moresearch:'status,',
					qyid:user.id
				}
				console.log('go taocan',fdata);
			tongyong(fdata).then(res => {
				if(res.code==1){
				  console.log(res);
				  this.tableData=res.data
				}else{
					this.$message({
						type: 'error',
						message: res.msg
					})
				}    
			})
		},
		// 读取数据库结构
		getsjk(){
			console.log('go sjk');
	 
			let  fdata= {
					sjk: this.sjk,
				
				}
			sjk(fdata).then(res => {
				if(res.code==1){
				  console.log('sjk',res);
				  this.sjkData=res.data
				}else{
					this.$message({
						type: 'error',
						message: res.msg
					})
				}    
			})
		},
		
    }
}
</script>
<style lang="scss" scoped>
    .pagination{
        width: 100%;
        margin: 30px auto;
    }
    ::v-deep .el-select{
            width: 100%;
            
    }
  
</style>