<template>
    <div class="recharge-record-wrapper">
		   <el-button  >{{$t('nums')}}:{{nums}}</el-button>  
		   <el-button  >{{$t('lasttime')}}:{{lasttime}}</el-button>  
		   <el-button @click="chongzhi" >{{$t('chongzhi')}}</el-button>  
            <el-table
                :data="tableData"
                stripe
                style="width: 100%">
                <el-table-column
                prop="addtime"
                :label="$t('date')"
                 >
					<template slot-scope="scope">
				         <div>{{scope.row.addtime | formatDate}} </div>
				       </template>
                </el-table-column>
                <el-table-column
                prop="method"
                :label="$t('paymentmethod')"
                >
                </el-table-column>
                <el-table-column
                prop="amount"
                :label="$t('money')">
                </el-table-column>
				       <el-table-column
                prop="nums"
                :label="$t('nums')">
                </el-table-column>
				
				       <el-table-column
                prop="days"
                :label="$t('days')">
                </el-table-column>
				
				
				   <el-table-column>
									<template slot-scope="scope">
										<el-button @click="handleClick(scope.row.id)" type="text" size="small">发票</el-button>
									</template>
						</el-table-column>  
            </el-table>
         <div class="pagination flex-center">
                 <el-pagination background  @current-change="handleCurrentChange"
         :current-page="currentPage"
         :page-size="pageSize"   layout="total,prev,pager,next" :total="tableData.length"></el-pagination>
             </div>
    </div>
</template>
<script>
	import {tongyong,getUserinfo} from '@/api'
	import { formatDate } from '../../assets/date.js'
export default {
    data() {
        return {
			balance:0,
			nums:0,
			lasttime:'',
			currentPage: 1,  // 当前页码
			 pageSize: 10,  // 每页显示的行数
            tableData: [
                {timer: '2018.3.1', paymentmethod: '现金', money: '14'},
             
            ],
        }
    },  
	filters: {
      formatDate: function (time) {
              time = time * 1000     //注意：time应为格式为13位unix时间戳，如果是10位的unix时间戳，需要乘以1000
                       let date = new Date(time)
                       return formatDate(date, 'yyyy-MM-dd hh:mm:ss')
        } 
	  },

	created()
	   {
			   this.getlist()
			   this.getUserinfo()
		   },
    methods: {
		chongzhi(){
		     this.$router.push('/corporateInformation/packagePurchase/');	
		},
       paypal() {//充值paypal
	       
           
       },
       handleClick(id) {
           window.open('https://w7.khshop.online/app/index.php?i=1&c=entry&m=qicheng_messenger&do=makepdf&action=api_getpayinfo&id='+id)
       },
	 beforeHandleCommand(e){
	 	console.log(e);
	 },
	  // 页面切换方法
	   handleCurrentChange(val) {
	     this.currentPage = val;
	   },
	   getlist(){
	   			console.log('go messenger_invoicelist');
	   			var user = JSON.parse(window.localStorage.getItem('userinfo'))
	   			let  fdata= {
	   					sjk: 'messenger_paylist',
	   					action:'list',
	   					// status:this.status,
	   					// moresearch:'status,',
	   					qyid:user.id
	   				}
	   			tongyong(fdata).then(res => {
	   				if(res.code==1){
	   				  console.log(res);
	   				  this.tableData=res.data
	   				}else{
	   					this.$message({
	   						type: 'error',
	   						message: res.msg
	   					})
	   				}    
	   			})
	   },
	   getUserinfo(){
	   	 var user = JSON.parse(window.localStorage.getItem('userinfo'))
	   	 let  fdata= {
	   	 		qyid:user.id
	   	 	}
	   	 getUserinfo(fdata).then(res => {
	   	 	if(res.code==1){
	   	 	  console.log(res);
	   		   
	   	 	  this.balance=res.data.balance
	   	 	  this.nums=res.data.nums
	   	 	  this.lasttime=res.data.lasttime
	   	 	}else{
	   	 		this.$message({
	   	 			type: 'error',
	   	 			message: res.msg
	   	 		})
	   	 	}    
	   	 })
	   },
	   
    }
}
</script>
<style lang="scss" scoped>
    .pagination{
        width: 100%;
        margin: 30px auto;
    }
    ::v-deep .el-select{
            width: 100%;
            
    }
  
</style>