<template>
    <div class="packagePurchase-wrapper">
		<el-tabs  @tab-click="handleClick">
		    <el-tab-pane  label="Whatsapp推廣服務"  name="item.id"  ></el-tab-pane>
		</el-tabs>
        <el-row :gutter="8">
            <el-col :xs="24" :span="10" :lg="8"  v-for="item in combo" :key="item.label">
                <el-card style="min-height: 280px;" v-if="item.typeid==1" shadow="always">
					<div class="title"><img class="logo" width="40px" src="@/assets/images/shop.png"/>{{item.title}} </div>
					<div class="tips">{{item.detail}}</div>
                    <div class="value">
						<div v-for="items in remark(item.content)">{{items}}</div>
					 
					</div>
                    <div @click="payit(item)" class="price">${{item.price}}</div>
				</el-card>
				
            </el-col>
        </el-row>
		
<!-- 		
		<el-tabs  @tab-click="handleClick">
		    <el-tab-pane  label="Whatsapp推廣服務+ 通知服務"  name="item.id"  ></el-tab-pane>
		</el-tabs>
        <el-row :gutter="12">
            <el-col :xs="24" :span="10" :lg="8"  v-for="item in combo" :key="item.label">
                <el-card v-if="item.typeid==2" shadow="always">
					<div class="title">
						<img class="logo" width="40px" src="@/assets/images/shop.png"/>
						<img  class="logo" width="40px" src="@/assets/images/display.png"/>{{item.title}} </div>
					<div class="tips">{{item.detail}}</div>
                    <div class="value">
						<div v-for="items in remark(item.content)">{{items}}</div>
					 
					</div>
                    <div @click="payit(item)" class="price">${{item.price}}</div>
				</el-card>
            </el-col>
        </el-row>
		 -->
		<!-- pay -->
		<el-dialog
		    title="選擇支付方式"
		    :visible.sync="dialogVisible"
			:width="$store.state.isMobile? '50%' :'50%'"
		    :before-close="handleClose">
		        <el-radio-group v-model="pay_type">
		             <el-radio v-for="(item,index) in paymentList" :key="index" :disabled="item.disabled" :label="item.code">  {{item.name}}</el-radio>
		        </el-radio-group>	
		        <span slot="footer" class="dialog-footer">
		            <el-button @click="dialogVisible = false">取 消</el-button>
		            <el-button @click="pay" type="primary" >確 定</el-button>
		        </span>
		</el-dialog>	 
    </div>
</template>
<script>
	import {tongyong} from '@/api'
export default {
	
    data() {
        return {
			pay_type:'',
			tcid:'',
			
			dialogVisible:false,
            combo: [
               ],
			 paymentList: [
                // {name: 'Paypal', code: 'paypal'},
                {name: 'Wechat Pay', code: 'wechat'},
                {name: 'Alipay', code: 'alipay'},
                {name: '支票/轉賬', code: 'zhipiao'},
				],
			   
        }
    },
	  created() {
		   this.gettaocanlist()
		  },
	  methods: {
		  handleClick(){
			  
		  },
		  remark (str) {
		      
		  			return  str.split(',')
		  		},
		  pay(){
			  var user = JSON.parse(window.localStorage.getItem('userinfo'))
			  console.log(user);
			  		 
			  console.log(this.pay_type);
			  console.log(this.price);
			  if(this.pay_type=='paypal'){
				window.open('https://w7.khshop.online/app/index.php?i=1&c=entry&m=qicheng_messenger&do=paypal&price='+this.price+'&qyid='+user.id+'&tcid='+this.tcid,'_Self')
			  }
			  
			  if(this.pay_type=='wechat'){
				window.open('https://w7.khshop.online/app/index.php?i=1&c=entry&m=qicheng_messenger&do=api_payasia_pc&network=Wechat&price='+this.price+'&qyid='+user.id+'&tcid='+this.tcid,'_blank')
			  }	
					
			  if(this.pay_type=='alipay'){
				window.open('https://w7.khshop.online/app/index.php?i=1&c=entry&m=qicheng_messenger&do=api_payasia_pc&network=Alipay&price='+this.price+'&qyid='+user.id+'&tcid='+this.tcid,'_Self')
			  }
			  
			  	  if(this.pay_type=='zhipiao'){
					  
					     this.$message('支票請將底單 email：business@nowaittech.com')
						 this.dialogVisible =false;
						 
					  }
			  //https://w7.khshop.online/app/index.php?i=1&c=entry&m=qicheng_messenger&do=api_payasia_pc&price=0.1&tcid=1
			  
			  
			
		  },
		  
		  payit(i){
			  this.price=i.price 
			  this.tcid=i.id 
			  this.dialogVisible = true 
		  },
		  handleClose() {
		      this.dialogVisible = !this.dialogVisible
		  },
		//&do=api_tongyong&sjk=messenger_taocanlist&action=list
		gettaocanlist(){
			console.log('go temp');
			let  fdata= {
					sjk: 'messenger_taocanlist',
					action: 'list',
				}
			tongyong(fdata).then(res => {
				if(res.code==1){
				  console.log(res);
				  this.combo=res.data
				}else{
					this.$message({
						type: 'error',
						message: res.msg
					})
				}    
			})
		}
		
	}
}
</script>
<style lang="scss" scoped>
	.logo {    
		margin-bottom: -8px;
		margin-right: 5px;
	}
    .packagePurchase-wrapper{
        .title{
            font-size: 20px;
            font-weight: 600;
        }
        .tips{
			min-height: 30px;
            font-size: 14px;
            color: rgba(0,0,0,.45);
            font-weight: 200;
            margin-top: 16px;
        }
        .value{
			min-height: 130px;
            font-size: 15px;
            font-weight: bold;
            color: #0a0b2f6e;
            text-align: left;
            margin: 20px 0;
			
        }
        .price{
            font-size: 18px;
            font-weight: bold;
            color: #ff6700;
			position:relative;
			bottom: 20px;
			right: 20px;
            text-align: right;
        }
    }
	@media screen and (max-width: 168px){
		.packagePurchase-wrapper{
			.title{
				font-size: 22px;
			}
			.tips{
				font-size: 16px;
				margin-top: 6px;
			}
			.value{
				margin: 12px 0;
				font-size: 16px;
				  color: #0a0b2f6e;
				 text-align: left;
			}
		}
		::v-deep .el-col-xs-24{
			margin-bottom: 16px;
		}
    }
</style>