<template>
    <div class="template-wrapper">
        <el-button @click="dialogVisible=true">{{$t('addTemplate')}}</el-button>
        <el-tabs v-model="activeName" @tab-click="handleClick">
            <el-tab-pane :label="item.label" :name="item.id" v-for="item in tab" :key="item.id"></el-tab-pane>
        </el-tabs>
        <el-table
            :data="tableData.slice((currentPage - 1) * pageSize, currentPage * pageSize)"
            stripe
            style="width: 100%">
			<el-table-column
			prop="addtime"
			:label="$t('date')"
			width="180">
			<template slot-scope="scope">
			         <div>{{scope.row.addtime | formatDate}} </div>
			       </template>
			</el-table-column>
           	<el-table-column
			prop="type"
			:label="$t('type')"
			width="180">
			<template slot-scope="scope">
			         <div v-if="scope.row.type==1">{{$t('textTemplate')}}  </div>
			         <div v-if="scope.row.type==2">{{$t('picturesAndText')}}  </div>
			         <div v-if="scope.row.type==3">{{$t('ComprehensiveTemplate')}}  </div>
			       </template>
			</el-table-column>
           
            <el-table-column
            prop="title"
            :label="$t('templateName')"
            width="">
            </el-table-column>
            <el-table-column   :label="$t('status')">
			 <template slot-scope="scope">
			          <div>{{scope.row.status=='0'?$t('daishenhe'):$t('yitongguo')}}{{scope[0]}}</div>
			 </template>
            </el-table-column>
			
			  <el-table-column
			      prop="operation"
			      label="操作"
			      align="center"
			      width="180">
			     <template slot-scope="scope">
			     <div class="operationVid">
			       <span class="operationBef" @click="delitem(scope.row.id)"  v-if="scope.row.status=='0'">删除</span>
			    
				<!-- <el-dropdown trigger="click"  >
			            <span class="el-dropdown-link">
			              操作<i class="el-icon-arrow-down el-icon--right"></i>
			            </span>
			            <el-dropdown-menu slot="dropdown">
			                <el-dropdown-item :command="beforeHandleCommand(scope,'订单详情')" >删除</el-dropdown-item>
			           </el-dropdown-menu>
			        </el-dropdown> -->
			    </div>
			     </template>
			    </el-table-column>
		 
        </el-table>
        <div class="pagination flex-center">
            <el-pagination background  @current-change="handleCurrentChange"
    :current-page="currentPage"
    :page-size="pageSize"   layout="total,prev,pager,next" :total="tableData.length"></el-pagination>
        </div>

       <!-- add -->
        <el-dialog :title="$t('addTemplate')" :visible.sync="dialogVisible" :width="$store.state.isMobile? '90%' :'556px'" :before-close="handleClose">
            <div class="add-template">
                <el-form ref="form" :model="form">

                    <el-form-item :label="$t('templateType')">
                        <el-select v-model="form.type" placeholder="">
                            <el-option :label="$t('textTemplate')" value="1"></el-option>
                            <el-option :label="$t('picturesAndText')" value="2"></el-option>
                            <el-option :label="$t('ComprehensiveTemplate')" value="3"></el-option>
                        </el-select>
                    </el-form-item>

                    <el-form-item :label="$t('templateName')">
                        <el-input v-model="form.title"></el-input>
                    </el-form-item>

                    <el-form-item    :label="$t('TemplateText')">
                        <el-input v-model="form.detail" type="textarea" :rows="2"></el-input>
                    </el-form-item>
					
					<el-form-item v-if="form.type == 3"   :label="$t('templateLink')">
                        <el-input v-model="form.link" type="textarea" :rows="2"></el-input>
                    </el-form-item>

       <el-form-item v-if="form.type != 1" label="上传文件:" prop="png">
            <el-upload
              class="upload-demo"
              ref="upload"
              action
              :http-request="httpRequest"
              :before-upload="beforeUpload"
              :on-exceed="handleExceed"
              :limit="1"
            >
              <el-button slot="trigger" size="small" type="primary"
                >选取文件</el-button
              >
              <div slot="tip" class="el-upload__tip">
                只能上传.png文件，且不超过2M
              </div>
            </el-upload>
          </el-form-item>
<!-- 		  
                    <el-form-item v-if="form.type == 2">
                        <img width="100%" :src="form.pic" alt="">
                        <el-upload
                            action="https://jsonplaceholder.typicode.com/posts/"
                            list-type="picture-card"
                            :on-preview="handlePictureCardPreview"
                            :on-remove="handleRemove">
                             <i class="el-icon-plus"></i>
                        </el-upload>
                    </el-form-item> -->
                </el-form>
            </div>
            <span slot="footer" class="dialog-footer">
                <el-button @click="dialogVisible = false">{{$t('Cancel')}}</el-button>
                <el-button v-if="form.type == 1"  type="primary" @click="sure">{{$t('Sure')}}</el-button>
                <el-button v-if="form.type != 1"  type="primary" @click="submitImportForm">{{$t('Sure')}}{{form.type}}</el-button>
            </span>
        </el-dialog>

    </div>
</template>
<script>
	import { formatDate } from '../../assets/date.js'
	import axios from 'axios'
import {tongyong,funk} from '@/api'
import {addTemplate} from '@/api'
export default {
    data() {
        return {
			  fileList: [],
			 currentPage: 1,  // 当前页码
			  pageSize: 10,  // 每页显示的行数
            activeName: '0',
            dialogVisible: false,
            tab: [
                {label: this.$t('underApproval'), id: '0'},
                {label: this.$t('passed'), id: '1'}
            ],
			tableData:[],
            tableData1: [
           
                {timer: '2018-01-01', name: '测试模板001', status: '审核中'},
            ],
			status:0,
            form: {
                type: '1',
                title: '',
				detail:'',
                text: '',
                qyid: '',
                status: '',
                link: '',
                pic: ''
            },
        }
    },
	filters: {
	  formatDate: function (time) {
	          time = time * 1000     //注意：time应为格式为13位unix时间戳，如果是10位的unix时间戳，需要乘以1000
	                   let date = new Date(time)
	                   return formatDate(date, 'yyyy-MM-dd hh:mm:ss')
	    } 
	  },
	
	created() {
			   this.gettemplist()
			  },
    methods: {
		beforeHandleCommand(e){
			console.log(e);
		},
		 // 页面切换方法
		  handleCurrentChange(val) {
		    this.currentPage = val;
		  },
        handleClick(tab, event) {
            console.log(tab.index);
			this.status=tab.index
			this.currentPage=1
			 this.gettemplist()
        },
        handleClose() {
            this.dialogVisible = !this.dialogVisible
        },
        handlePictureCardPreview() {

        },
        handleRemove() {

        },
        // 添加模版点击确定
        sure() {
			 var user = JSON.parse(window.localStorage.getItem('userinfo'))
			 console.log(user);
		 
			 this.form.qyid= user.id
			 this.form.status= 0
            addTemplate(this.form).then(res => {
                if(res.code==1){
                    this.$message({
                        type: 'success',
                        message: res.msg
                    })
                    this.dialogVisible = false
				    this.gettemplist()
                }else{
                    this.$message({
                        type: 'error',
                        message: res.msg
                    })
                }    
            })
        },
		//删除某个
		delitem(id){
			this.$confirm('您确定删除吗？').then(_ => {
			
			    console.log('del..');
				let  fdata= {
						sjk: 'messenger_templatelist',
						action:'del',
						id:id
					}
				tongyong(fdata).then(res => {
					if(res.code==1){
					  console.log(res);
					  this.gettemplist()
					}else{
						this.$message({
							type: 'error',
							message: res.msg
						})
					}    
				})
			}).catch(_ => {
			
			　 
			
			})
			
			
		},
		
		gettemplist(){
			console.log('go taocan');
			var user = JSON.parse(window.localStorage.getItem('userinfo'))
		
			let  fdata= {
					sjk: 'messenger_templatelist',
					action:'list',
					status:this.status,
					moresearch:'status,',
					qyid:user.id
				}
			tongyong(fdata).then(res => {
				if(res.code==1){
				  console.log(res);
				  this.tableData=res.data
				}else{
					this.$message({
						type: 'error',
						message: res.msg
					})
				}    
			})
		},
		

		httpRequest(option) {
		  this.fileList.push(option);
		},
		// 上传前处理
		beforeUpload(file) {
		  let fileSize = file.size;
		  const FIVE_M = 5 * 1024 * 1024;
		  //大于5M，不允许上传
		  if (fileSize > FIVE_M) {
		    this.$message.error("最大上传5M");
		    return false;
		  }
		  //判断文件类型，必须是xlsx格式
		  let fileName = file.name;
		  let reg = /^.+(\.png)$/;
		  if (!reg.test(fileName)) {
		    this.$message.error("只能上传图片!");
		    return false;
		  }
		  return true;
		},
		// 文件数量过多时提醒
		handleExceed() {
		  this.$message({ type: "error", message: "最多支持1个附件上传" });
		},
		
		submitImportForm() {
			var user = JSON.parse(window.localStorage.getItem("userinfo"));
			console.log(user);
		   
		   if(!this.form.title) {
			    this.$message.error("请输入title");
			   return
		   }
		   if(this.fileList.length==0) {
			    this.$message.error("请选择文件");
			   return
		   }
		 
		  let paramsData = new FormData();
		  // 将上传文件数组依次添加到参数paramsData中
		  console.log("filelist", this.fileList);
		  
		  this.fileList.forEach((x) => {
		    console.log(x.file);
		    paramsData.append("file", x.file);
		  });
		  // 将输入表单数据添加到params表单中
		 
		  paramsData.append("qyid",  user.id);
		  paramsData.append("title",  this.form.title);
		  paramsData.append("type",  this.form.type);
		  paramsData.append("detail",  this.form.detail);
		  paramsData.append("link",  this.form.link);


		  //这里根据自己封装的axios来进行调用后端接口
		  console.log("paramsData", paramsData.get("file"));
		
		   axios.post("/app/index.php?i=1&c=entry&m=qicheng_messenger&do=api_tmp_insert",paramsData).then(res=>{
		                console.log(res);
		            })
		 // this.$refs.importFormRef.resetFields()//清除表单信息
		  this.$refs.upload.clearFiles()//清空上传列表
		  this.fileList = []//集合清空
		  this.title = ''
		  this.dialogVisible = false//关闭对话框
		  this.gettemplist()
		 
		},
		
		
    }
}
</script>
<style lang="scss" scoped>
    .pagination{
        width: 100%;
        margin: 30px auto;
    }
    ::v-deep .el-select{
            width: 100%;
            
    }
	
  
</style>