<template>
    <div class="template-wrapper">
		<div style="display: flex; ">
			<el-button style="flex:1" @click="addzuowei">{{$t('addSet')}}</el-button> 
       
			<el-button style="flex:1"  @click="dialogVisible_key=true">{{$t('updatekey')}}</el-button> 
			<el-button style="flex:1"  @click="ewm">{{$t('ewm')}}</el-button> 
			<el-button style="flex:1"   @click="qingling">{{$t('qingling')}}</el-button> 
			<!-- <el-button><a target="_blank" style="flex:1" :href="'https://m.me/'+pageid+'?ref='+pageData.keyword">Test</a> </el-button> -->
			<el-button style="flex:1" >
				<a target="_blank" style="flex:1" :href="'http://show.nowaittech.com/#/home?pageid='+pageid+'&qyid='+qyid">{{$t('Show Panel')}}</a> 
				<button class="tag-read" :data-clipboard-text="'http://show.nowaittech.com/#/home?pageid='+pageid+'&qyid='+qyid" @click="copy">复制</button>
				</el-button>
		    <el-button style="flex:1"  @click="gettemplist()">{{$t('refresh')}}</el-button> 
	   </div>
<!-- 		<el-tabs v-model="activeName" @tab-click="handleClick">
            <el-tab-pane :label="item.label" :name="item.id" v-for="item in tab" :key="item.id"></el-tab-pane>
        </el-tabs> -->
        <el-table
            :data="tableData.slice((currentPage - 1) * pageSize, currentPage * pageSize)"
            stripe
            style="width: 100%">
			
			<el-table-column
			    prop="operation"
			    label="操作"
			    align="center"
			    width="180">
			   <template slot-scope="scope">
			   <div class="operationVid">
			     <span v-if="scope.row.nownum < scope.row.allnum " class="operationBef" @click="callnext(scope.row)"  ><el-button>叫號</el-button> </span>
			     <span style="width: 10px;" class="operationBef"  > </span>
			   
			  </div>
			   </template>
			  </el-table-column>
					 
            <el-table-column v-for="item in sjkData"
				:prop="item.key"
				:label="item.title"
				>
				  <template   slot-scope="scope">
				  <div v-if="item.type=='choose'">{{scope.row[item.key]=='0'? item.optionst[0]:item.optionst[1]}}</div>
				  <div v-if="item.type!='choose'">{{scope.row[item.key]}} </div>
				  
				</template>  
            </el-table-column>
           
 <el-table-column
    prop="nownum"
    :label="$t('nownum')"
    width="">
    </el-table-column>
 <el-table-column
    prop="allnum"
    :label="$t('allnum')"
    width="">
    </el-table-column>
	
	

			  <el-table-column
			      prop="operation"
			      label="操作"
			      align="center"
			      width="180">
			     <template slot-scope="scope">
			     <div class="operationVid">
			       <span class="operationBef" @click="edititem(scope.row)"  >编辑</span>
			       <span style="width: 10px;" class="operationBef"  > </span>
			       <span class="operationBef" @click="delitem(scope.row.id)"  >删除</span>
 
			    </div>
			     </template>
			    </el-table-column>
		 
  
	
        </el-table>
        <div class="pagination flex-center">
            <el-pagination background  @current-change="handleCurrentChange"
    :current-page="currentPage"
    :page-size="pageSize"   layout="total,prev,pager,next" :total="tableData.length"></el-pagination>
        </div>

       <!-- add -->
        <el-dialog :title="$t('addZuowei')" :visible.sync="dialogVisible" width="556px" :before-close="handleClose">
            <div class="add-template">
                <el-form ref="form" :model="form">

                    <el-form-item :label="$t('zuoweiType')">
                        <el-select v-model="form.zimu" placeholder="">
                            <el-option :label="$t('setA')" value="A"></el-option>
                            <el-option :label="$t('setB')" value="B"></el-option>
                            <el-option :label="$t('setC')" value="C"></el-option>
                            <el-option :label="$t('setD')" value="D"></el-option>
                        </el-select>
                    </el-form-item>

                    <el-form-item :label="$t('templateName')">
                        <el-input v-model="form.type"></el-input>
                    </el-form-item>

               

                    <el-form-item v-if="form.type == 2">
                        <img width="100%" :src="form.pic" alt="">
                        <el-upload
                            action="https://jsonplaceholder.typicode.com/posts/"
                            list-type="picture-card"
                            :on-preview="handlePictureCardPreview"
                            :on-remove="handleRemove">
                             <i class="el-icon-plus"></i>
                        </el-upload>
                    </el-form-item>
                </el-form>
            </div>
            <span slot="footer" class="dialog-footer">
                <el-button @click="dialogVisible = false">{{$t('Cancel')}}</el-button>
                <el-button type="primary" @click="sure">{{$t('Sure')}}</el-button>
            </span>
        </el-dialog>
		
		
   <el-dialog :title="$t('edit')" :visible.sync="dialogVisible_key" width="556px" :before-close="handleClose">
            <div class="add-template">
                <el-form ref="form" :model="formkey">

                 <!--   <el-form-item :label="$t('keyword')">
                        <el-input v-model="formkey.keyword"></el-input>
                    </el-form-item>     -->
						<!-- <el-form-item :label="$t('pagewhatsapp')">
				 <span><a :href="'#/wlogin?whatsapp='+pageid+'&pageid='+pageid">扫码接入</a></span> 
                   <el-input v-model="formkey.pagewhatsapp"></el-input>
						 
                    </el-form-item>	-->			 
		 			<el-form-item :label="$t('businesswhatsapp')">
					 <span> 填写接入的businessAPI 号码</span>
                        <el-input v-model="formkey.businesswhatsapp"></el-input>
						 
                    </el-form-item> 
					<el-form-item :label="$t('guangliyuanphone')">
					 <span> </span>
                        <el-input v-model="formkey.guangliyuanphone"></el-input>
						 
                    </el-form-item>  
               <!--    <el-form-item :label="$t('wd1')">
                        <el-input v-model="formkey.wd1"></el-input>
                    </el-form-item>
					<el-form-item :label="$t('wd2')">
                        <el-input v-model="formkey.wd2"></el-input>
                    </el-form-item>
					<el-form-item :label="$t('wd3')">
                        <el-input v-model="formkey.wd3"></el-input>
                    </el-form-item>
 -->
              
                </el-form>
            </div>
            <span slot="footer" class="dialog-footer">
                <el-button @click="dialogVisible_key = false">{{$t('Cancel')}}</el-button>
                <el-button type="primary" @click="surekey">{{$t('Sure')}}</el-button>
            </span>
        </el-dialog>
		
   <el-dialog :title="$t('ewm')" :visible.sync="dialogVisible_ewm" width="556px" :before-close="handleClose">
            <div class="add-template">
				<div>H5:<a :href="ewmdata.url">Link</a>
				<button class="tag-read" :data-clipboard-text="ewmdata.url" @click="copy">复制</button>
				  <div><img :src="ewmdata.path" /></div> 
				</div>		
				
				<div>Whatsapp:<a :href="ewmdata.wurl">Link</a>
				<button class="tag-read" :data-clipboard-text="ewmdata.wurl" @click="copy">复制</button>
				  <div><img :src="ewmdata.whatsapp" /></div> 
				</div>
 
				
			</div>
   </el-dialog>
   
    </div>
</template>
<script>
import Clipboard from 'clipboard'
import {tongyong,sjk,jh_paidui,api_tongyong_edit } from '@/api'
import {addPaiduiSet,funk} from '@/api'
export default {
    data() {
        return {
			ewmdata:{url:'',wurl:'',path:'',whatsapp:''},
			action:'',
			pageid:'',
			qyid:'',
			sjk:'messenger_paiduilist',
			currentPage: 1,  // 当前页码
			pageSize: 10,  // 每页显示的行数
            activeName: '0',
            dialogVisible: false,
            dialogVisible_key: false,
            dialogVisible_ewm: false,
            tab: [
                {label: this.$t('underApproval'), id: '0'},
                {label: this.$t('passed'), id: '1'}
            ],
			tableData:[],
			pageData:[],
			sjkData:[],
            tableData1: [
              
            ],
			status:0,
			  sform: {
				  parmas:{}
			  },
            form: {
                type: '1',
                zimu: '',
           
                qyid: '',
         
            },
			formkey:{
				keyword:'kkk'
			}
        }
    },
	created(option) {
					if(this.$route.params.pageid){
						this.pageid=this.$route.params.pageid
						this.getpagedata()
					}
			
					console.log('sjk',this.sjk);
					if(this.sjk){
					   this.getsjk()
					   this.gettemplist()
				    }
			  },
    methods: {
		copy () {
		   var clipboard = new Clipboard('.tag-read')
		   clipboard.on('success', e => {
		      console.log('复制成功')
			  this.$message({
			      type: 'success',
			      message: '复制成功'
			  })
		      //  释放内存
		     clipboard.destroy()
		   })
		    clipboard.on('error', e =>{
		     // 不支持复制
		     console.log('该浏览器不支持复制')
		     // 释放内存
		     clipboard.destroy()
		   })
		},
		addzuowei(){
			 this.dialogVisible=true
			 this.action='insert'
			 this.form= {type: '1',zimu: '',qyid: ''}
		},
		beforeHandleCommand(e){
			console.log(e);
		},
		 // 页面切换方法
		  handleCurrentChange(val) {
		    this.currentPage = val;
		  },
        handleClick(tab, event) {
            console.log(tab.index);
			this.status=tab.index
			this.currentPage=1
			 this.gettemplist()
        },
        handleClose() {
            this.dialogVisible = false
            this.dialogVisible_key = false
            this.dialogVisible_ewm = false
        },
        handlePictureCardPreview() {

        },
        handleRemove() {

        },
        // 添加模版点击确定
        sure() {
			 var user = JSON.parse(window.localStorage.getItem('userinfo'))
			 console.log(user);
		 
			 this.form.qyid  = user.id
			 this.form.pageid= this.pageid
			 this.form.action= this.action
			let data = JSON.stringify(this.form)
			 
			 this.sform.parmas= data
			 
            addPaiduiSet(this.sform).then(res => {
				console.log(res);
                if(res.code==1){
                    this.$message({
                        type: 'success',
                        message: res.msg
                    })
                    this.dialogVisible = false
					  this.gettemplist()
                }else{
                    this.$message({
                        type: 'error',
                        message: res.msg
                    })
                }    
            })
        },
        // 修改key
        surekey() {
			 var user = JSON.parse(window.localStorage.getItem('userinfo'))
			 console.log(user);
		 
			 
			 this.formkey.sjk   = 'messenger_pagelist'
			 this.formkey.updatekey   = 'keyword,wd1,wd2,wd3,pagewhatsapp,businesswhatsapp,guangliyuanphone'
			 this.formkey.setkey   ='id' 
			 
			 
		 
			  let data = JSON.stringify(this.formkey);
			 
			  this.sform.parmas= data
			 
            api_tongyong_edit(this.sform).then(res => {
				console.log(res);
                if(res.code==1){
                    this.$message({
                        type: 'success',
                        message: res.msg
                    })
                    this.dialogVisible_key = false
					this.getpagedata()
                }else{
                    this.$message({
                        type: 'error',
                        message: res.msg
                    })
                }    
            })
        },
		edititem(data){
			this.dialogVisible=true
			this.action='edit'
			this.form = data 
		},
		callnext(data){
			 console.log('del..');
			 let  fdata= {
			 		sjk: this.sjk,
			 		 
			 		id:data.id
			 	}
			 jh_paidui(fdata).then(res => {
				 console.log(res);
			 	if(res.code<0){
			 	 
			 	 this.$message({
			 	 	type: 'error',
			 	 	message: res.msg
			 	 })
			 	}else{
			 		this.gettemplist()
			 	}    
			 })
		},
		//删除某个
		delitem(id){
			this.$confirm('您确定删除吗？').then(_ => {
			
			    console.log('del..');
				let  fdata= {
						sjk: this.sjk,
						action:'del',
						id:id
					}
				tongyong(fdata).then(res => {
					if(res.code==1){
					  console.log(res);
					  this.gettemplist()
					}else{
						this.$message({
							type: 'error',
							message: res.msg
						})
					}    
				})
			}).catch(_ => {
			
			　 
			
			})
			
			
		},
		qingling(){
		   	let  fdata= {
		   			do: 'qingling',
		   		 
		   			pageid:this.pageid,
		   		 
		   		 
		   		
		   		}
		this.$confirm('您确定清零吗？').then(_ => {	 
		   	funk(fdata).then(res => {
		   		if(res.code>0){
		   		  console.log(res);
		   	      this.gettemplist()
				  this.$message({
				      type: 'success',
				      message: 'ok'
				  })
		   		}else{
		   			this.$message({
		   				type: 'error',
		   				message: 'error'
		   			})
		   		}    
		   	})
			}).catch(_ => {
			
			　 
			
			})
		},
		ewm(){
			var user = JSON.parse(window.localStorage.getItem('userinfo'))
			console.log(user);
			this.dialogVisible_ewm=true	 
			this.qyid  = user.id
			let  fdata= {
					do: 'ewm',
					qyid:this.qyid,
					pageid:this.pageid,
					ref:this.pageData.keyword,
					type:'link'
				
				}
				console.log('go ewm',fdata);
			funk(fdata).then(res => {
				if(res.code==1){
				  console.log(res);
				  this.ewmdata=res
				  console.log(this.ewmdata)
				  // const html ='<div style="text-algin:center;">H5:<a target="_blank"  href="'+res.url+'">link</a> <br><img src='+res.path+' /> <br>Whatsapp:<a target="_blank"  href="'+res.wurl+'">link</a> <button class="tag-read" :data-clipboard-text="aaa" @click="copy">复制</button></el-button><br> <img src='+res.whatsapp+' /> <br></div>' 
				  // this.$alert(html, '預約排隊二維碼', {
				  //           dangerouslyUseHTMLString: true
				  //         });
				  
				  
				}else{
					this.$message({
						type: 'error',
						message: res.msg
					})
				}    
			})
		},
		// 我的模板列表
		gettemplist(){
			
			var user = JSON.parse(window.localStorage.getItem('userinfo'))
			this.qyid = user.id 
			let  fdata= {
					sjk: this.sjk,
					action:'list',
					  pageid:this.pageid,
					 moresearch:'pageid,',
					qyid:user.id
				}
				console.log('go taocan',fdata);
			tongyong(fdata).then(res => {
				if(res.code==1){
				  console.log(res);
				  this.tableData=res.data
				}else{
					this.$message({
						type: 'error',
						message: res.msg
					})
				}    
			})
		},	
		// 读取page信息
		getpagedata(){
			
			var user = JSON.parse(window.localStorage.getItem('userinfo'))
 
			let  fdata= {
					sjk: 'messenger_pagelist',
					action:'list',
					pageid:this.pageid,
					moresearch:'pageid,',
					qyid:user.id
				}
				console.log('go page',fdata);
				
			tongyong(fdata).then(res => {
				if(res.code==1){
				  console.log(res);
				  this.pageData  =  res.data
				  this.formkey   = res.data[0]   ;
				}else{
					this.$message({
						type: 'error',
						message: res.msg
					})
				}    
			})
		},
		// 读取page
		getsjk(){
			console.log('go sjk');
	 
			let  fdata= {
					sjk: this.sjk,
				
				}
			sjk(fdata).then(res => {
				if(res.code==1){
				  console.log('sjk',res);
				  this.sjkData=res.data
				}else{
					this.$message({
						type: 'error',
						message: res.msg
					})
				}    
			})
		},
		
    }
}
</script>
<style lang="scss" scoped>
    .pagination{
        width: 100%;
        margin: 30px auto;
    }
	.operationBef{
		margin: 10px;
	}
    ::v-deep .el-select{
            width: 100%;
            
    }
  
</style>